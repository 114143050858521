import React from 'react'
import "./JobParams.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios'
import moment from 'moment'
import { DataGrid } from '@mui/x-data-grid';
import { Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useForm} from 'react-hook-form'
import {connect} from 'react-redux'
import TwoBDialog from '../Utils/TwoBDialog'
function CRUDJobParam(props) {
    const [display,setDisplay]=React.useState(false)
    const {handleSubmit,formState:{errors},register,setValue} = useForm()
    const [type,setType] = React.useState("")
    const [open,setOpen] = React.useState(false)
    const onSubmit = (data)=>{
        console.log(data,type)
        if(data.jobParamName.length>0 && type.length>0){
            if(isString(props.location.state)){
                //create job param
                axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/createJobParam`,{
                    name:data.jobParamName,
                    type
                },{headers:{token:props.user.user}})
                .then(res=>{
                    console.log(res)
                    props.history.push("/jobparams")
                })
                .catch(err=>{
                    console.log(err.response)
                })
            }else{
                //edit job param
                axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/editJobParam`,{
                    id:props.location.state._id,
                    object:{
                        name:data.jobParamName,
                        type
                    }
                },{headers:{token:props.user.user}})
                .then(res=>{
                    console.log(res)
                    props.history.push("/jobparams")
                })
                .catch(err=>{
                    console.log(err.response)
                })
            }
        }
    }
    function isString(value) {
        return typeof value === 'string' || value instanceof String;
    }
    console.log(props)
    React.useEffect(()=>{
        console.log(isString(props.location.state))

        if(!isString(props.location.state)){
            setValue("jobParamName",props.location.state.name)
            setType(props.location.state.type)
        }else{
            setType(props.location.state)
        }

    },[])

    const handleDelete =  ()=>{
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/deleteJobParam`,{id:props.location.state._id},{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            props.history.push("/jobparams")
        })
        .catch(err=>{
            
        })
    }

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 p-0">
            <Dashhead id={5} display={display} />
            </div>

            <TwoBDialog 
            title="Delete Job Param"
            description="Are you sure you want to delete this job param"
            open={open}
            setOpen={setOpen}
            leftButton="Cancel"
            rightButton="Submit"
            handleSubmit={handleDelete}
            />
            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container p-0" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>


            <section className="home-container p-4">
                {isString(props.location.state)?<>
                <h1 className="">Create {props.location.state}</h1>
                <form onSubmit = {handleSubmit(onSubmit)} className="mr-5">
                    <TextField className="mt-2 mb-4" fullWidth variant="outlined" id="outlined-basic" label="Enter Job Param Name" {...register('jobParamName',{required:true})} />
                    <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Job Param Type</InputLabel>
                    <Select
                    disabled
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="Job Param Type"
                    onChange={(e)=>setType(e.target.value)}
                    >
                    <MenuItem value={'pg'}>pg</MenuItem>
                    <MenuItem value={'ug'}>ug</MenuItem>
                    <MenuItem value={'product'}>product</MenuItem>
                    <MenuItem value={'functionalarea'}>functionalarea</MenuItem>
                    <MenuItem value={'industry'}>industry</MenuItem>
                    </Select>
                </FormControl>
                
                <Button className="my-4" type="submit" variant="contained">Submit</Button>

                </form>
                </>:
                <>
                <h1 className="">Edit {props.location.state.type}</h1>
                <form onSubmit = {handleSubmit(onSubmit)} className="mr-5">
                    <TextField className="mt-2 mb-4" fullWidth variant="outlined" id="outlined-basic" label="Enter Job Param Name" {...register('jobParamName',{required:true})} />
                    <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Job Param Type</InputLabel>
                    <Select
                    disabled
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="Job Param Type"
                    onChange={(e)=>setType(e.target.value)}
                    >
                    <MenuItem value={'pg'}>pg</MenuItem>
                    <MenuItem value={'ug'}>ug</MenuItem>
                    <MenuItem value={'product'}>product</MenuItem>
                    <MenuItem value={'functionalarea'}>functionalarea</MenuItem>
                    <MenuItem value={'industry'}>industry</MenuItem>
                    </Select>
                </FormControl>
                <Button className="my-4" type="submit" variant="contained">Submit</Button>
                <div className="my-3">
                <Button onClick={()=>setOpen(true)} color="error" variant="outlined">Delete Job Param</Button>
                </div>

                </form>
                </>
                }
                

            </section>



             </div>
    </div>
    )
}
const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
  }
  export default connect(mapStateToProps)(CRUDJobParam)
