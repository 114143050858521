import React from 'react'
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import {Button, TextField} from '@mui/material'
import moment from 'moment'
import {useForm} from 'react-hook-form'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import {connect} from 'react-redux'
function CreateRecruiter(props) {
    const [display,setDisplay]=React.useState(false)
    const {handleSubmit,register,formState:{errors},setValue}=useForm()
    const [radio,setRadio] = React.useState('true')
    console.log(props)
    React.useEffect(()=>{
      if(props.location.state){
        let detail = props.location.state;
        setValue("fullName",detail.fullName)
        setValue("email",detail.email)
        setValue("mobileNo",detail.mobileNo)
        setValue("companyName",detail.companyName)
        setValue("designation",detail.designation)
      }
      
    },[])

    const onSubmit = (data)=>{
        console.log(data)
        if(props.location.state){
          axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/editRecruiter`,{id:props.location.state._id,object:data},{headers:{token:props.user.user}})
          .then(res=>{
            console.log(res)
            props.history.push("/recruiters")
          })
          .catch(err=>{
            console.log(err)
          })

        }else{
          axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/recruiter/signup`,{
            "email":data.email,
            "password":data.password,
            "fullName":data.fullName,
            "mobileNo":data.mobileNo,
            "companyName":data.companyName,
            "designation":data.designation,
            "subscriptionId":"635a98177ca2905a363e4dcb"
          })
          .then(res=>{
            console.log(res)
            props.history.push('/recruiters')
          })
          .catch(err=>{
            console.log(err)
          })
        }

    }

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 p-0">
            <Dashhead id={4} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container p-0" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>


            <section className="home-container">
               
            <form className="create-form" onSubmit = {handleSubmit(onSubmit)}>
            <h1>{props.location.state?"Edit Candidate":"Create Candidate"}</h1>
            <TextField variant="outlined" className="my-3" id="outlined-basic" label="FullName" {...register("fullName",{required:true})} fullWidth />
            <TextField variant="outlined" className="my-3" id="outlined-basic" label="Email" {...register("email",{required:true})} fullWidth />
            <TextField variant="outlined" className="my-3" id="outlined-basic" label="Password" {...register("password",{required:false})} fullWidth />
            <TextField variant="outlined" className="my-3" id="outlined-basic" label="Mobile Number" {...register("mobileNo",{required:true})} fullWidth />
            <TextField variant="outlined" className="my-3" id="outlined-basic" label="Company Name" {...register("companyName",{required:true})} fullWidth />
            <TextField variant="outlined" className="my-3" id="outlined-basic" label="Designation" {...register("designation",{required:true})} fullWidth />

            <div>
            <Button variant="contained" type="submit">{props.location.state?"Edit Candidate":"Create Candidate"}</Button>
            </div>
            </form>

            </section>


             </div>
    </div>
    )
}
const mapStateToProps = ({EventUser})=>{
  return {
      user:EventUser
  }
}
export default connect(mapStateToProps)(CreateRecruiter)
