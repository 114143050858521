import React from 'react'
import "./Recruiters.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment'
import {Link, useParams} from 'react-router-dom'
import {getAge} from '../Utils/Functions'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PublishIcon from '@mui/icons-material/Publish';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {Chip,Button} from '@mui/material'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import WorkIcon from '@mui/icons-material/Work';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ArticleIcon from '@mui/icons-material/Article';
import DescriptionIcon from '@mui/icons-material/Description';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import TabPanel from '@mui/lab/TabPanel';
import LinkIcon from '@mui/icons-material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel'
import {connect} from 'react-redux';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import AssignCustomPlan from './AssignCustomPlan';
import Autocomplete from '@mui/material/Autocomplete';
import TwoBDialog from '../Utils/TwoBDialog'
function RecruiterDetail(props) {
    const [display,setDisplay]=React.useState(false)
    const [data,setData] = React.useState(null)
    const [value, setValue] = React.useState('1');
    const [plans,setPlans] = React.useState([])
    const [currentPlan,setCurrentPlan] = React.useState('')
    const [flag,setFlag] = React.useState(false)
    const [open,setOpen] = React.useState(false)
    const [open2,setOpen2] = React.useState(false)
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    let params = useParams();
    console.log(props)
    console.log(params)
    const getPlans = ()=>{
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/subscription/allSubscription`)
        .then(res=>{
          console.log(res)
          if(res.data.msg==="success"){
            setPlans(res.data.result)
            
          }
          
        })
        .catch(err=>{
          console.log(err)
        })
      }
    React.useEffect(()=>{
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getRecruiterData`,{recruiterId:params.id},{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            setData({...res.data.result})
            setCurrentPlan(res.data.result.candidate.subscription.name)
            getPlans()
        })
        .catch(err=>{
            console.log(err)
        })
    },[flag])


    const handleSubmit = (id,plan)=>{
        //create plan
        if(id){
            axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/changePlan`,{availablePlanCreditsId:data.candidate.availablePlanCredits._id,id:data.candidate._id,recruiter:true,subscriptionId:id,plan},{headers:{token:props.user.user}})
            .then(res=>{
                console.log(res)
                setOpen(false)
                setFlag(!flag)
            })
        }else{
            axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/changePlan`,{availablePlanCreditsId:data.candidate.availablePlanCredits._id,id:data.candidate._id,recruiter:true,subscriptionId:plans.filter(item=>item.name===currentPlan)[0]._id,plan:plans.filter(item=>item.name===currentPlan)[0]},{headers:{token:props.user.user}})
            .then(res=>{
                console.log(res)
                setFlag(!flag)
            })
        }
        
    }

    const renderPlanName = (id)=>{
        let plan = plans.filter(item=>item._id===id)[0]
        return plan.name;
      }
      const columns2 = [
        { field: 'id', headerName: 'ID',width:20},
        //{ field: 'brand', headerName: 'Brand Name',valueGetter:(param)=>param.value.name,width:150},
        { field: 'fullName', headerName: 'Name',valueGetter:(param)=>renderPlanName(param.row.subscriptionId),width:100},
        { field: 'expiry', headerName: 'Expiry',valueGetter:(param)=>param.row.expiry,width:200},
        { field: 'paymentId', headerName: 'Payment Id',valueGetter:(param)=>param.row.paymentId.razorpay_payment_id,width:200},
        { field: 'subscriptionId', headerName: 'Subscription ID',valueGetter:(param)=>param.row.subscriptionId,width:250},
        {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}
    
    
      ];
      const handleSubmit2 = ()=>{
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/deleteRecruiter`,{id:data.candidate._id},{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            setOpen(false)
            props.history.push("/candidates")
        })
    }
    const renderPersonalInfo = ()=>{
        return  <>
        <AssignCustomPlan
        open={open}
        setOpen={setOpen}
        plans={plans.filter(item=>item.custom===true)}
        handleSubmit = {handleSubmit}
        />
            <TwoBDialog 
            open={open2} 
            setOpen={setOpen2}
            title="Delete Recruiter"
            description="Are you sure you want to delete this recruiter"
            leftButton="Cancel"
            rightButton="Submit"
            handleSubmit={handleSubmit2}
            />
        <Button onClick={()=>setOpen2(true)} color="error" variant="outlined">Delete Recruiter</Button>
        <Button onClick={()=>props.history.push("/createrecruiter",data.candidate)} className="ml-3" variant="outlined">Edit Recruiter</Button>
        <section className="candidate-info-section row m-auto shadow-sm">
        <div className="col-2 img-cont">
            <img src={data.candidate.profilePicture?`${process.env.REACT_APP_DEVELOPMENT}/api/image/${data.candidate.profilePicture}`:"/profile.png"} alt="profile" />
        </div>
        <div className="col-6 info-cont">
        <h1>{data.candidate.fullName}</h1>
        <p className="bold-text">{data.candidate.education.length>0?`${data.candidate.education[0].name}, ${data.candidate.education[0].universityName}`:""}</p>
            <p className="grey-text">{data.candidate.gender} | {data.candidate.dob?getAge(data.candidate.dob):"DOB Missing"}</p>
                <div className="row m-auto sub-info">
                <p className="mx-2"><LocalPhoneIcon sx={{marginRight:.1}} /> <b>{data.candidate.mobileNo}</b></p>
                    <p className="mx-2"><AlternateEmailIcon sx={{marginRight:.1}} /> <b>{data.candidate.email}</b></p>
                    <p className="mx-2"><LocationOnIcon sx={{marginRight:.1}} /> <b>{data.candidate.userLocation.city}, {data.candidate.userLocation.state}</b></p>
                </div>
                <p className="grey-text">{data.candidate.resumeTagline?data.candidate.resumeTagline:"Tagline Missing"}</p>
            <div className="row m-auto chip-div">
            {data.candidate.skills.length>0?data.candidate.skills.map((item,index)=><Chip label={item} key={index} className="mx-2" color="primary" />):<p style={{color:"red",fontWeight:"bold"}}>You haven't added any skills yet, add from the skills section below</p>}
            </div>
        </div>
        <div className="col-4 resume-div">
            <div className="resume-head shadow-sm">
                {data.candidate.gstin?<div className="resume-child row m-auto">
                    <div className="col-2">
                        <InsertDriveFileIcon color="primary" />
                    </div>
                    <div className="col-10 underline-hover" onClick={()=>window.open(`${process.env.REACT_APP_DEVELOPMENT}/api/pdf/${data.candidate.gstin}`, '_blank')}>
                        <h4>{data.candidate.fullName}'s GSTIN.{data.candidate.gstin.split(".")[1]}</h4>
                        <p>Click to view GSTIN</p>
                    </div>

                </div>:
                <div className="resume-child">
                <p>No GSTIN</p>
                </div>
                }
                {data.candidate.pan?<div className="resume-child row m-auto">
                    <div className="col-2">
                        <InsertDriveFileIcon color="primary" />
                    </div>
                    <div className="col-10 underline-hover" onClick={()=>window.open(`${process.env.REACT_APP_DEVELOPMENT}/api/pdf/${data.candidate.pan}`, '_blank')}>
                        <h4>{data.candidate.fullName}'s Pan.{data.candidate.pan.split(".")[1]}</h4>
                        <p>Click to view Pan</p>
                    </div>

                </div>:
                <div className="resume-child">
                <p>No Pan</p>
                </div>
                }
            </div>

            <div className="subs-div align-items-center">
                <div className="m-1">
                    <h5>Currently on {data.candidate.subscription.name} Tier</h5>
                </div>
                <div className="m-2">
                <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Change Plan</FormLabel>
                <RadioGroup
                    value={currentPlan}
                    onChange={(e)=>setCurrentPlan(e.target.value)}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                >{
                    plans.map((item,index)=>!item.custom&&<FormControlLabel value={item.name} control={<Radio />} label={item.name} key={index} />)
                }
                    
                </RadioGroup>
                <Button onClick={()=>{
                    handleSubmit()
                }}>Change Plan</Button>
                <Button onClick={()=>setOpen(true)} variant="outlined">Choose Custom Plan</Button>
                </FormControl>
                </div>
            </div>
        </div>
    </section>

    {/* <section className="row m-auto view-section-head justify-content-around">
        <div className="col-3 view-section-child row m-auto align-items-center justify-content-around shadow-sm">
            <div>
            <p>Total Profile Views</p>
            </div>

            <div className="ml-2">
                <h2>1465</h2>
            </div>
        </div>
        <div className="col-3 view-section-child row m-auto align-items-center justify-content-around shadow-sm">
            <div>
            <p>Total Profile Bookmarks</p>
            </div>

            <div className="ml-2">
                <h2>65</h2>
            </div>
        </div>
        <div className="col-3 view-section-child row m-auto align-items-center justify-content-around shadow-sm">
            <div>
            <p>Your Hiring Rate</p>
            </div>

            <div className="ml-2">
                <h2>45%</h2>
            </div>
        </div>
    </section> */}

    <section className="company-info-section shadow-sm row m-auto">
                <div className="col-2">
                <img src={data.candidate.companyImg?`${process.env.REACT_APP_DEVELOPMENT}/api/image/${data.candidate.companyImg}`:"/user.png"} alt="profile" />
                </div>
                <div className="col-10 row m-auto">
                <div className="col-11">
                <h2>Current Company Details</h2>
                <h3>{data.candidate.companyName}</h3>
                <p className="grey-text">{data.candidate.companyDescription}</p>
                <div className="row m-auto">
                    <div className="col-6 key-holders">
                        <p><LocationOnIcon /> {data.candidate.companyLocation.city}, {data.candidate.companyLocation.state}</p>
                    </div>
                    <div className="col-6 key-holders">
                        <p><LinkIcon /> {data.candidate.companyUrl}</p>
                    </div>
                </div>
                <div className="mt-4" />
                {
                    data.candidate.companyTags.map((item,index)=><Chip key={index} color="primary" className="m-2" label={item} />)
                }
                </div>
                </div>
            </section>

    {/* view sections */}

    {/* profile info sections */}
    <section className="row m-auto info-sections justify-content-around">
        <div className="col-5 info-child shadow-sm">
            {/* heading of personal info */}
            <div className="row m-auto justify-content-between">
                <div>
                <h2>Personal Info</h2>
                </div>
                <div>
                    {/* <Button>Add Details</Button> */}
                </div>
            </div>

            {/* content of perosnal ifno */}
            <div className="info-sub-content">
                <div>
                <p className="key">Date of Birth</p>
                <p className="val">{moment.parseZone(data.candidate.dob).local().format("dddd, MMMM Do YYYY")}</p>
                </div>
            </div>
            <div className=" info-sub-content">
                <div>
                <p className="key">Languages</p>
                <p className="val">
                    {
                        data.candidate.languages.map((item,index)=><span key={index}>{item} | </span>)
                    }
                </p>
                </div>
            </div>
            <div className=" info-sub-content">
                <div>
                <p className="key">Marital Status</p>
                <p className="val">{data.candidate.maritalStatus}</p>
                </div>
            </div>
            <div className=" info-sub-content">
                <div>
                <p className="key">Category</p>
                <p className="val">{data.candidate.category}</p>
                </div>
            </div>
        </div>
        <div className="col-5 info-child shadow-sm">
            <div className="">
                <div>
                <h2>Employment</h2>
                </div>
            </div>
            {
                data.candidate.workExperience.length>0?
                data.candidate.workExperience.map((item,index)=>(
                    <div key={index} className=" info-sub-content">
                    <div className="p-0 col-12">
                    <p className="key">{item.designation} {item.name}</p>
                    <p className="val">{item.description}</p>
                    <p className="datefromto">{moment.parseZone(item.startDate).local().format("DD/MM/YYYY")} - {item.current?"Current":moment.parseZone(item.endDate).local().format("DD/MM/YYYY")}</p>
                    </div>
                </div>
                ))
                :<p><b>No Employment Details</b></p>
            }

        </div>
        <div className="col-5 info-child shadow-sm">
            <div className="">
                <div>
                <h2>Education</h2>
                </div>
            </div>
            {
                data.candidate.education.length>0?
                data.candidate.education.map((item,index)=>(
                    <div key={index} className=" info-sub-content">
                   <div className="p-0 col-8">
                    <p className="key">{item.name}</p>
                    <p className="val">{item.universityName}</p>
                    <p className="datefromto">{moment.parseZone(item.startDate).local().format("DD/MM/YYYY")} - {item.current?"Current":moment.parseZone(item.endDate).local().format("DD/MM/YYYY")}</p>
                    </div>
                </div>
                ))
                :<p><b>Add Education Details</b></p>
            }
        </div>
        <div className="col-5 info-child shadow-sm">
            <div className="">
                <div>
                <h2>Key Skills</h2>
                </div>
            </div>
            {
                data.candidate.skills.map((item,index)=><Chip className="m-2" color="primary" label={item} key={index} />)
            }
        </div>
        <div className="col-5 info-child shadow-sm">
            <div className="">
                <div>
                <h2>Preferences</h2>
                </div>
            </div>
            {
                data.candidate.preferences.map((item,index)=><Chip className="m-2" color="primary"label={item} key={index} />)
            }
        </div>

    </section>
    </>
    }

    const renderJobs = (item)=>{
        return <Link to={`/jobdetail/${item._id}`} className="link-underline">
        <section className={`col-12 shadow-sm job-apply-head row m-auto`}>
        <div className='img-div col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1'>
            <img src={data.candidate.companyImg?`${process.env.REACT_APP_DEVELOPMENT}/api/image/${data.candidate.companyImg}`:"/user.png"} alt="logo1" />
        </div>
        <div className='content-div col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9'>
            <h3 className="m-0">{item.title}</h3>
            <p className="m-0 companyName">{item.companyName}</p>
            <h4 className="m-0">{item.product}</h4>
                <div className='row mt-2 mx-auto align-items-center'>
                    <div>
                    <Rating name="read-only" value={3} readOnly />
                    </div>
                    <div>
                    <p className="total-reviews">(47 Reviews)</p>
                    </div>
                </div>
            <div className="row my-2 mx-auto key-features">
                <div className="m-1">
                    <WorkIcon />
                    <span className='key-headline m-2'>{item.experience.min} - {item.experience.max} Yrs</span>
                </div>
                <div className="m-1">
                    <CurrencyRupeeIcon />
                    <span className='key-headline m-2'>{item.ctc.min} - {item.ctc.max} P.A</span>
                </div>
                <div className="m-1">
                    <FmdGoodIcon />
                    <span className='key-headline m-2'>{item.jobLocation.city} | {item.jobLocation.state} | {item.jobLocation.country}</span>
                </div>
            </div>
            <div className="row my-2 mx-auto key-features">
                <div className="m-1">
                    <ArticleIcon />
                    <span className='key-headline m-2'>{item.qualification.ug} in CSE</span>
                </div>
                <div className="m-1">
                    <DescriptionIcon />
                    <span className='key-headline m-2'>{item.qualification.pg} in Finance</span>
                </div>
                <div className="m-1">
                    <Inventory2Icon />
                    <span className='key-headline m-2'>{item.industry}</span>
                </div>
            </div>



        </div>
    </section>
    </Link>
    }

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 p-0">
            <Dashhead id={4} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container p-0" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>


             {data&&<section className="home-container">
            <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Personal Info" value="1" />
                <Tab label="Payments" value="2" />
                <Tab label="Jobs Created" value="3" />
                <Tab label="Hired Candidates" value="4" />
                <Tab label="Rejected Candidates" value="5" />
            </TabList>
            </Box>
            <TabPanel value="1">{renderPersonalInfo()}</TabPanel>
            <TabPanel value="2">
            <div className='p-4' style={{ height: '80vh', width: '100%' }}>
                <DataGrid
                    rows={data.candidate.paymentHistory.map((item,index)=>({...item,id:index+1}))}
                    columns={columns2}
                    autoPageSize
                    checkboxSelection
                    // onRowClick={(item,ev)=>props.history.push(`/recruiterdetail/${item.row._id}`)}
                />
                </div>
            </TabPanel>
            <TabPanel value="3">{data.jobResponse[0].jobsCreated.map((item)=>renderJobs(item))}</TabPanel>
            <TabPanel value="4">
            <div className='p-4' style={{ height: '80vh', width: '100%' }}>
                <DataGrid
                    rows={data.jobResponse[0].hiredCandidates.map((item,index)=>({...item,id:index+1}))}
                    columns={columns3}
                    autoPageSize
                    checkboxSelection
                    onRowClick={(item,ev)=>props.history.push(`/candidatedetail/${item.row.jobCandidates.user[0]._id}`)}
                />
                </div>
            </TabPanel>
            <TabPanel value="5">
            <div className='p-4' style={{ height: '80vh', width: '100%' }}>
                <DataGrid
                    rows={data.jobResponse[0].rejectedCandidates.map((item,index)=>({...item,id:index+1}))}
                    columns={columns3}
                    autoPageSize
                    checkboxSelection
                    onRowClick={(item,ev)=>props.history.push(`/candidatedetail/${item.row.jobCandidates.user[0]._id}`)}
                />
                </div>
            </TabPanel>
        </TabContext>
           
            </section>}



             </div>
    </div>
    )
    
}
const columns3 = [
    { field: 'id', headerName: 'ID',width:20},
    //{ field: 'brand', headerName: 'Brand Name',valueGetter:(param)=>param.value.name,width:150},
    { field: 'fullName', headerName: 'Name',valueGetter:(param)=>param.row.jobCandidates.user[0].fullName,width:150},
    { field: 'jobName', headerName: 'Job Name',valueGetter:(param)=>param.row.title,width:200},
    { field: 'email', headerName: 'Email',valueGetter:(param)=>param.row.jobCandidates.user[0].email,width:200},
    { field: 'userLocation', headerName: 'Location',valueGetter:(param)=>param.row.jobCandidates.user[0].userLocation.city,width:150},
    { field: 'subscription', headerName: 'Subscription',valueGetter:(param)=>param.row.jobCandidates.user[0].subscription,width:150},
    { field: 'mobileNo', headerName: 'Mobile No',valueGetter:(param)=>param.row.jobCandidates.user[0].mobileNo,width:150},
    { field: 'hiringRate', headerName: 'Hiring Rate',valueGetter:(param)=>param.row.jobCandidates.user[0].hiringRate,width:150},
    {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.row.jobCandidates.user[0].createdAt).local().format("DD/MM/YY"),width:120}


  ];
  const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
  }
  export default connect(mapStateToProps)(RecruiterDetail)
