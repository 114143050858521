import React from 'react'
import "./Blogs.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios'
import moment from 'moment'
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import {connect} from 'react-redux'
function Blogs(props) {
    const [display,setDisplay]=React.useState(false)
    const [featuredData,setFeaturedData] = React.useState([])

    React.useEffect(()=>{
         axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getAllBlogs`,{headers:{token:props.user.user}})
         .then(res=>{
            console.log(res)
            if(res.data.result.length>0){
                let arr = res.data.result.map((item,index)=>({...item,id:index+1}))
                setFeaturedData(arr)
            }
         })
    },[])
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 p-0">
            <Dashhead id={8} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container p-0" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>


            <section className="home-container p-4">
                <h1 className="">Blogs</h1>
                <Button onClick={()=>props.history.push("/crudblogs")} variant="contained">Create Blogs</Button>
                <div style={{ height: '80vh', width: '100%' }}>
                <DataGrid
                    rows={featuredData}
                    columns={columns2}
                    autoPageSize
                    checkboxSelection
                    onRowClick={(item,ev)=>props.user.userInfo.type===1?props.history.push(`/crudblogs`,item.row):null}
                    // onRowClick={(item,ev)=>props.history.push('/crudblogs',item.row)}
                />
            </div>
                
            </section>



             </div>
    </div>
    )
}
const columns2 = [
    { field: 'id', headerName: 'ID',width:20},
    //{ field: 'brand', headerName: 'Brand Name',valueGetter:(param)=>param.value.name,width:150},
    { field: 'title', headerName: 'Title',valueGetter:(param)=>param.row.title,width:100},
    { field: 'description', headerName: 'Description',valueGetter:(param)=>param.row.description,width:200},
    { field: 'image', headerName: 'Image',valueGetter:(param)=>param.row.img,width:300},
    { field: '_id', headerName: '_id',valueGetter:(param)=>param.row._id,width:200},
    {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}


  ];
  const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
  }
  export default connect(mapStateToProps)(Blogs)
