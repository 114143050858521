import './App.scss';
import {Switch,Route}  from 'react-router-dom'
import {connect} from 'react-redux'
import Home from "./components/Home"
import Jobs from './components/Jobs/Jobs';
import Candidates from './components/Candidates/Candidates';
import Recruiters from './components/Recruiters/Recruiters';
import JobParams from './components/JobParams/JobParams';
import JobDetail from './components/Jobs/JobDetail';
import CreateJob from './components/Jobs/CreateJob';
import CandidateDetail from './components/Candidates/CandidateDetail';
import CreateCandidate from './components/Candidates/CreateCandidate';
import RecruiterDetail from './components/Recruiters/RecruiterDetail';
import CRUDJobParam from './components/JobParams/CRUDJobParam';
import Subscription from './components/Subscription/Subscription';
import CreateSubscription from './components/Subscription/CreateSubscription';
import CreateRecruiter from './components/Recruiters/CreateRecruiter';
import SignIn from './components/Auth/SignIn';
import FeaturedData from './components/FeaturedData/FeaturedData';
import CrudFeaturedData from './components/FeaturedData/CrudFeaturedData';
import Blogs from './components/Blogs/Blogs';
import CrudBlog from './components/Blogs/CrudBlog';
import NotFound from './NotFound';

function App(props) {
  console.log("approps",props)
  const renderRoutes = ()=>{
    if(props.user.userInfo){
      if(props.user.userInfo.type===1){
        return <>
      <Route path="/home" component={Home} />
      <Route path="/jobs" component={Jobs} />
      <Route path="/createjob" component={CreateJob} />
      <Route path="/jobdetail/:id" component={JobDetail} />
      <Route path="/candidates" component={Candidates} />
      <Route path="/createcandidate" component={CreateCandidate} />
      <Route path="/candidatedetail/:id" component={CandidateDetail} />
      <Route path="/recruiterdetail/:id" component={RecruiterDetail} />
      <Route path="/recruiters" component={Recruiters} />
      <Route path="/createrecruiter" component={CreateRecruiter} />
      <Route path="/jobparams" component={JobParams} />
      <Route path="/crudjobparam" component={CRUDJobParam} />
      <Route path="/subscription" component={Subscription} />
      <Route path="/createsubscription" component={CreateSubscription} />
      <Route path="/featured" component={FeaturedData} />
      <Route path="/crudfeatureddata" component={CrudFeaturedData} />
      <Route path="/blogs" component={Blogs} />
      <Route path="/crudblogs" component={CrudBlog} />
        </>
      }else if(props.user.userInfo.type===2){
        return <>
      <Route path="/home" component={Home} />
      <Route path="/jobs" component={Jobs} />
      <Route path="/createjob" component={CreateJob} />
      </>
      }else if(props.user.userInfo.type===3){
        return <>
        <Route path="/home" component={Home} />
      <Route path="/candidates" component={Candidates} />
      <Route path="/createcandidate" component={CreateCandidate} />
        </>
    }else if(props.user.userInfo.type===4){
        return <>
        <Route path="/home" component={Home} />
      <Route path="/blogs" component={Blogs} />
      <Route path="/crudblogs" component={CrudBlog} />
        </>
    }
    }
  }

  return (
    <Switch>
      <Route exact path="/" component={SignIn} />
      {
        renderRoutes()
      }
      <Route path="/" component={NotFound} />
    </Switch>
  );
}

const mapStateToProps = ({EventUser})=>{
  return {
    user:EventUser
  }
}

export default connect(mapStateToProps)(App);
