import React from 'react'
import "./FeaturedData.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios'
import moment from 'moment'
import { DataGrid } from '@mui/x-data-grid';
import { Button,TextField } from '@mui/material';
import {connect} from 'react-redux'
import {useForm} from 'react-hook-form'
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditProfileImage from '../Candidates/EditProfileImage';
import DeleteIcon from '@mui/icons-material/Delete';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { styled } from '@mui/material/styles';
import TwoBDialog from '../Utils/TwoBDialog'
const Input = styled('input')({
  display: 'none',
});
function CrudFeaturedData(props) {
    const [display,setDisplay]=React.useState(false)
    const {handleSubmit,register,formState:{errors},setValue}=useForm()
    const [type, setType] = React.useState('');
    const [image,setImage] = React.useState(null)
    const [imageFile,setImageFile] = React.useState(null)
    const [open,setOpen] = React.useState(false)
    const [open2,setOpen2] = React.useState(false)
    const handleChange = (event) => {
        setType(event.target.value);
    };
  
    React.useEffect(()=>{
        if(props.location.state){
            setValue("name",props.location.state.name)
            setType(props.location.state.type)
            setImage(`${process.env.REACT_APP_DEVELOPMENT}/api/image/${props.location.state.img}`)
        }
    },[])
    const imageSubmit = (image,file)=>{
        setImage(image)
        setImageFile(file)
    }

    const onSubmit = (data)=>{
        console.log(data)
        if(type!==""){
            if(props.location.state){
                axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/editFeaturedData`,{id:props.location.state._id,object:{
                    name:data.name,
                    type
                }},{headers:{token:props.user.user}})
                .then(res=>{
                    console.log(res)
                    if(res.data.msg==="success"){
                        if(type==="companyImg" && imageFile){
                        const formdata1 = new FormData();
                        formdata1.append('file',imageFile)
                        formdata1.append('featuredId',props.location.state._id)
                        formdata1.append('img',props.location.state.img)
                        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/edit-featuredData-img`,formdata1,{headers:{Accept:'application/json','Content-Type':"multipart/form-data",token:props.user.user}})
                        .then(res=>{
                            console.log(res)                            
                            props.history.push("/featured")
                        })
                        }
                        // else if(type==="bannerImg" && imageFile){
                        //     const formdata1 = new FormData();
                        //     formdata1.append('file',imageFile)
                        //     formdata1.append('featuredId',props.location.state._id)
                        //     formdata1.append('img',props.location.state.img)
                        //     axios.post(`${process.env.REACT_APP_DEVELOPMENT}/edit-featuredData-img`,formdata1,{headers:{Accept:'application/json','Content-Type':"multipart/form-data",token:props.user.user}})
                        //     .then(res=>{
                        //         console.log(res)                            
                        //         props.history.push("/featured")
                        //     })
                        // }
                        else{
                            props.history.push("/featured")
                        }
                    }
                })
            }else{
                axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/createFeaturedData`,{
                    name:data.name,
                    type
                },{headers:{token:props.user.user}})
                .then(res=>{
                    console.log(res)
                    if(res.data.msg==="success"){
                        if(type==="companyImg" && imageFile){
                        const formdata1 = new FormData();
                        formdata1.append('file',imageFile)
                        formdata1.append('featuredId',res.data.result._id)
                        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/upload-featuredData-img`,formdata1,{headers:{Accept:'application/json','Content-Type':"multipart/form-data",token:props.user.user}})
                        .then(res=>{
                            console.log(res)                            
                            props.history.push("/featured")
                        })
                        }
                        // else if(type==="bannerImg" && imageFile){
                        //     const formdata1 = new FormData();
                        //     formdata1.append('file',imageFile)
                        //     formdata1.append('featuredId',res.data.result._id)
                        //     axios.post(`${process.env.REACT_APP_DEVELOPMENT}/upload-featuredData-img`,formdata1,{headers:{Accept:'application/json','Content-Type':"multipart/form-data",token:props.user.user}})
                        //     .then(res=>{
                        //         console.log(res)                            
                        //         props.history.push("/featured")
                        //     })
                        // }
                        
                        else{
                            props.history.push("/featured")
                        }
                    }
                })
            }

        }
    }

    const handleDelete = ()=>{
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/deleteFeaturedData`,{id:props.location.state._id},{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            props.history.push("/featured")
        })
        .catch(err=>{
            console.log(err.response)
        })
    }


    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 p-0">
            <Dashhead id={7} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container p-0" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>
             <EditProfileImage
            open={open}
            setOpen={setOpen}
            handleSubmit = {imageSubmit}
            />
            {props.location.state&&<TwoBDialog 
            title="Delete Featured Data"
            description="Are you sure you want to delete this data?"
            leftButton="Cancel"
            rightButton="Delete"
            open={open2}
            setOpen={setOpen2}
            handleSubmit={handleDelete}
            />}

            <section className="home-container p-4 featured-crud">
                <h1 className="">{props.location.state?"Edit":"Create"} Featured Data</h1>
                {props.location.state&&<Button onClick={()=>{setOpen2(true)}} variant='outlined' color="error">Delete Featured Data</Button>}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField   className="my-3" fullWidth id="outlined-basic" label="Name" {...register('name',{required:true})} />
                    <FormControl className="my-3" fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="Select Type"
                    onChange={handleChange}
                    >
                    <MenuItem value={'jobTag'}>Job Tag</MenuItem>
                    <MenuItem value={'companyImg'}>Company Image</MenuItem>
                    {/* <MenuItem value={'bannerImg'}>Banner Image</MenuItem> */}
                    </Select>
                </FormControl>
                {type==="companyImg"&&<>
                {!image&&<Button variant="outlined" onClick={()=>setOpen(true)}>Upload Image</Button>}
                        <div>
                        {
                            image&&<img style={{width:"30%"}} src={image} alt="profileimage" />
                        }
                        </div>
                        <div>
                        {image&&<Button onClick={()=>{
                            setImageFile(null)
                            setImage(null)}}>Delete Image</Button>}
                            </div>
                </>}


                {/* {type==="bannerImg"&&<>
                {(imageFile || image)?(
                    <div className="my-3 row justify-content-center">
                    <img alt="not fount" style={{width:"80%"}} src={imageFile?URL.createObjectURL(imageFile):image} />
                    <IconButton onClick={()=>{
                    setImage(null)
                    setImageFile(null)}} aria-label="delete" size="small" color='error'>
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
                    </div>
                ):null}
                <div className="mt-4 mb-2" style={{textAlign:"center"}}>
                <label htmlFor="contained-button-file">
                <Input 
                onChange={(event) => {
                    console.log(event.target.files);
                    setImageFile(event.target.files[0]);
                }}
                accept="image/*" id="contained-button-file" multiple type="file" />
                <Button variant="contained" component="span" endIcon={<CameraAltIcon />}>
                Upload image
                </Button>
                </label>
                </div>
                </>} */}

                <Button variant="contained" type="submit">{props.location.state?"Edit":"Create"} Featured Data</Button>
                </form>
                
                
            </section>



             </div>
    </div>
    )
}
const columns2 = [
    { field: 'id', headerName: 'ID',width:20},
    //{ field: 'brand', headerName: 'Brand Name',valueGetter:(param)=>param.value.name,width:150},
    { field: 'name', headerName: 'Name',valueGetter:(param)=>param.row.name,width:100},
    { field: 'type', headerName: 'Type',valueGetter:(param)=>param.row.type,width:200},
    { field: '_id', headerName: '_id',valueGetter:(param)=>param.row._id,width:200},
    {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}


  ];
  const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
  }
  export default connect(mapStateToProps)(CrudFeaturedData)
