import { TextField,Alert } from '@mui/material'
import React from 'react'
import "./SignIn.scss"
import {useForm} from 'react-hook-form'
import {Button} from '@mui/material'
import axios from 'axios'
import {setUser,storeUserInfo} from '../redux/user/userActions'
import {connect} from 'react-redux'

function SignIn(props) {
    const {handleSubmit,register,formState:{errors}}=useForm()
    const [error,setError] = React.useState("")

    const onSubmit = (data)=>{
        console.log(data)
        
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/adminlogin`,{...data})
        .then(res=>{
            console.log(res)
            setError("")
            props.setUser(res.data.result.token)
            props.storeUserInfo(res.data.result.userInfo)
            props.history.push("/home")
        })
        .catch(err=>{
            console.log(err.response)
            if(err.response.data){
                setError(err.response.data)
            }
        })
    }

  return (
    <div>
        <form onSubmit={handleSubmit(onSubmit)} className="auth-form">
        <img src="/banksterlogo.jpeg" alt="bankster-logo" />
        <h1 className="my-4">Welcome Admin</h1>
        <TextField className="my-3" {...register('email',{required:true})} fullWidth id="outlined-basic" variant="outlined"  label="Email" />
        <TextField className="my-3" {...register('password',{required:true})} fullWidth id="outlined-basic" variant="outlined"  label="Password" />
        <div className="my-3" style={{textAlign:"center"}}>
        {error.length>0&&<Alert className="alert" severity="error">{error}</Alert>}
        <Button type="submit" variant="contained">Sign In</Button>
        </div>
        </form>
    </div>
  )
}

const mapDispatchToProps = (dispatch)=>{
    return {
        setUser:(token)=>dispatch(setUser(token)),
        storeUserInfo:(userInfo)=>dispatch(storeUserInfo(userInfo))
    }
}

export default connect(null,mapDispatchToProps)(SignIn)