import React from 'react'
import "./Subscription.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios';
import {connect} from 'react-redux'
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment'
import { Button } from '@mui/material';
function Subscription(props) {
    const [display,setDisplay]=React.useState(false)
    const [recruiterSubscription,setRecruiterSubscription] = React.useState([])
    const [candidateSubscription,setCandidateSubscription] = React.useState([])
    React.useEffect(()=>{
      axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/subscription/allSubscription`,{headers:{token:props.user.user}})
      .then(res=>{
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/candidateSubscription/allSubscription`,{headers:{token:props.user.user}})
        .then(response=>{
            console.log(res,response)
            let arr = res.data.result.map((item,index)=>({...item,id:index+1}))
            let arr2 = response.data.result.map((item,index)=>({...item,id:index+1}))
            setRecruiterSubscription(arr)
            setCandidateSubscription(arr2)
        })
        
      })
    },[])
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 p-0">
            <Dashhead id={6} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container p-0" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>


            <section className="home-container p-3">
            
            <h1>Recruiter Subscription</h1>
            <Button onClick={()=>props.history.push("/createsubscription",{task:"create",type:"recruiter"})}>Create Recruiter Subscription</Button>
                <div className='' style={{ height: '40vh', width: '100%' }}>
                <DataGrid
                    rows={recruiterSubscription}
                    columns={columns2}
                    autoPageSize
                    checkboxSelection
                    onRowClick={(item,ev)=>props.history.push(`/createsubscription`,{task:'edit',type:"recruiter",data:item.row})}
                />
                </div>

                <h1>Candidate Subscription</h1>
                <Button onClick={()=>props.history.push("/createsubscription",{task:"create",type:"candidate"})}>Create Candidate Subscription</Button>
                <div className='' style={{ height: '40vh', width: '100%' }}>
                <DataGrid
                    rows={candidateSubscription}
                    columns={columns1}
                    autoPageSize
                    checkboxSelection
                    onRowClick={(item,ev)=>props.history.push(`/createsubscription`,{task:'edit',type:"candidate",data:item.row})}
                />
                </div>
            </section>



             </div>
    </div>
    )
}
const columns2 = [
    { field: 'id', headerName: 'ID',width:20},
    //{ field: 'brand', headerName: 'Brand Name',valueGetter:(param)=>param.value.name,width:150},
    { field: 'name', headerName: 'Name',valueGetter:(param)=>param.row.name,width:200},
    { field: 'amount', headerName: 'Amount',valueGetter:(param)=>param.row.amount,width:200},
    { field: 'jobPostings', headerName: 'Job Postings',valueGetter:(param)=>param.row.jobPostings,width:150},
    { field: 'subRecruiters', headerName: 'Sub Recruiters',valueGetter:(param)=>param.row.subRecruiters,width:150},
    { field: 'cvAccess', headerName: 'CV Access',valueGetter:(param)=>param.row.cvAccess,width:150},
    { field: 'timeline', headerName: 'Timeline',valueGetter:(param)=>param.row.timeline,width:150},
    { field: 'custom', headerName: 'Custom',valueGetter:(param)=>param.row.custom,width:150},
    {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}
  ];
  const columns1 = [
    { field: 'id', headerName: 'ID',width:20},
    //{ field: 'brand', headerName: 'Brand Name',valueGetter:(param)=>param.value.name,width:150},
    { field: 'name', headerName: 'Name',valueGetter:(param)=>param.row.name,width:200},
    { field: 'amount', headerName: 'Amount',valueGetter:(param)=>param.row.amount,width:200},
    { field: 'timeline', headerName: 'Timeline',valueGetter:(param)=>param.row.timeline,width:150},
    {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}
  ];
  const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
  }
  export default connect(mapStateToProps)(Subscription)
