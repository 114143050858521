import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import {TextField} from '@mui/material'
export default function AssignCustomPlan(props) {
    const [value, setValue] = React.useState("");
    console.log(value)
  return (
    <div>
     
      <Dialog
        open={props.open}
        onClose={()=>props.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Assign Custom Plan
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Choose the plan below and submit to assign a custom plan to recruiter
          </DialogContentText>
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
            setValue(newValue);
            }}
            id="controllable-states-demo"
            options={props.plans.map(item=>item.name)}
            fullWidth
            className="my-4"
            renderInput={(params) => <TextField {...params} label="Choose Custom Plan Name" />}
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>props.setOpen(false)}>Cancel</Button>
          <Button onClick={()=>{
            let subsId = props.plans.filter(item=>item.name===value)[0]
            props.handleSubmit(subsId._id,subsId)}} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

{/* <TwoBDialog title="Delete Event" description="Are you sure you want to delete this event"
rightButton="Delete"
leftButton="Cancel"
open={open}
setOpen={setOpen}
handleSubmit={handleSubmit}
/> */}
