import React from 'react'
import "./Dashhead.scss"
import {withRouter} from 'react-router'
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import WorkIcon from '@mui/icons-material/Work';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import {connect} from 'react-redux'
import { Button } from '@mui/material';
import {deleteUser} from './redux/user/userActions'
const Dashhead = (props) => {
    console.log(props);
    let {id,display} = props

    const renderOptions = ()=>{
        if(props.user.userInfo){
            if(props.user.userInfo.type===1){
                return <>
            {id===1?<div className="menu-container-active" onClick={()=>props.history.push('/home')}>
                <p><HomeIcon /> Home</p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('/home')} >
            <p><HomeOutlinedIcon /> Home</p>
            </div>
            }

            {id===2?<div className="menu-container-active" onClick={()=>props.history.push('/jobs')}>
                <p><WorkIcon /> Jobs</p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('/jobs')}>
            <p><WorkOutlineIcon /> Jobs</p>
            </div>
            }

            {id===3?<div className="menu-container-active" onClick={()=>props.history.push('/candidates')} >
                <p><PeopleAltIcon /> Candidates</p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('/candidates')} >
            <p><PeopleOutlineIcon /> Candidates</p>
            </div>
            }

            {id===4?<div className="menu-container-active" onClick={()=>props.history.push('/recruiters')}>
                <p><PermContactCalendarIcon /> Recruiters</p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('/recruiters')}>
            <p><PermContactCalendarOutlinedIcon /> Recruiters</p>
            </div>
            }

            {id===5?<div className="menu-container-active" onClick={()=>props.history.push('/jobparams')}>
                <p><PostAddOutlinedIcon /> Job Params</p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('/jobparams')}>
            <p><PostAddOutlinedIcon /> Job Params</p>
            </div>
            }

            {id===6?<div className="menu-container-active" onClick={()=>props.history.push('/subscription')}>
                <p><LocalAtmIcon /> Subscription</p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('/subscription')}>
            <p><LocalAtmIcon /> Subscription</p>
            </div>
            }

            {id===7?<div className="menu-container-active" onClick={()=>props.history.push('/featured')}>
                <p><LocalAtmIcon /> Featured Data</p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('/featured')}>
            <p><LocalAtmIcon /> Featured Data</p>
            </div>
            }

            {id===8?<div className="menu-container-active" onClick={()=>props.history.push('/blogs')}>
                <p><LocalAtmIcon /> Blogs</p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('/blogs')}>
            <p><LocalAtmIcon /> Blogs</p>
            </div>
            }
                </>
            }else if(props.user.userInfo.type===2){
                return <>
            {id===1?<div className="menu-container-active" onClick={()=>props.history.push('/home')}>
                <p><HomeIcon /> Home</p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('/home')} >
            <p><HomeOutlinedIcon /> Home</p>
            </div>
            }

            {id===2?<div className="menu-container-active" onClick={()=>props.history.push('/jobs')}>
                <p><WorkIcon /> Jobs</p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('/jobs')}>
            <p><WorkOutlineIcon /> Jobs</p>
            </div>
            }
                </>
            }else if(props.user.userInfo.type===3){
                return <>
                            {id===1?<div className="menu-container-active" onClick={()=>props.history.push('/home')}>
                <p><HomeIcon /> Home</p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('/home')} >
            <p><HomeOutlinedIcon /> Home</p>
            </div>
            }
                            {id===3?<div className="menu-container-active" onClick={()=>props.history.push('/candidates')} >
                <p><PeopleAltIcon /> Candidates</p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('/candidates')} >
            <p><PeopleOutlineIcon /> Candidates</p>
            </div>
            }
                </>
            }else if(props.user.userInfo.type===4){
                return <>
                            {id===1?<div className="menu-container-active" onClick={()=>props.history.push('/home')}>
                <p><HomeIcon /> Home</p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('/home')} >
            <p><HomeOutlinedIcon /> Home</p>
            </div>
            }
                            {id===8?<div className="menu-container-active" onClick={()=>props.history.push('/blogs')}>
                <p><LocalAtmIcon /> Blogs</p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('/blogs')}>
            <p><LocalAtmIcon /> Blogs</p>
            </div>
            }
                </>
            }
        }
    }


    return (
        
            
        <div className={display?"shadow-lg dashhead":'dashhead displayhidden'}>
            <h1>Admin</h1>
            {
                renderOptions()
            }

            <div className="my-3" style={{textAlign:"center"}}>
            <Button onClick={()=>{
                props.deleteUser();
                props.history.push("/")
            }} variant="contained">Log Out</Button>
            </div>



            
        </div>
    );
}

const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        deleteUser:()=>dispatch(deleteUser())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Dashhead));