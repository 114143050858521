import React from 'react'
import "./Recruiters.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment'
import {connect} from 'react-redux'
import {Button} from '@mui/material'
function Recruiters(props) {
    const [display,setDisplay]=React.useState(false)
    const [allRecruiters,setAllRecruiters] = React.useState([])
    const [headData,setHeadData] = React.useState(null)

    const getAllRecruiters = (count)=>{
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getAllRecruiters`,{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            if(res.data.result.length>0){
                let arr = res.data.result.map((item,index)=>({...item,id:index+1}))
                if(count ===1){
                    setAllRecruiters(arr.filter(i=>i.subscription.name==="Free"))
                }else if(count ===2){
                    setAllRecruiters(arr.filter(i=>i.subscription.name!=="Free"))
                }else{
                    setAllRecruiters(arr)
                }
                
            }
        })
    }

    React.useEffect(()=>{
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getRecruiterCardData`,{headers:{token:props.user.user}})
        .then(res=>{
               console.log(res) 
               setHeadData(res.data.result)
        })
        getAllRecruiters()
    },[])
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 p-0">
            <Dashhead id={4} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container p-0" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>


            <section className="home-container">
            {headData&&<>
                <div className="row m-auto justify-content-around box-parent">
                    <div className="mx-2 mb-4 col-3 box-info shadow-sm" onClick={()=>getAllRecruiters()}>
                        <h3>Total Recruiters</h3>
                        <h1>{headData.totalCount}</h1>
                    </div>
                    <div className="mx-2 mb-4 col-3 box-info shadow-sm" onClick={()=>getAllRecruiters(1)}>
                        <h3>Total Free Recruiters</h3>
                        <h1>{headData.totalCount - headData.recruiterPremiumCount}</h1>
                    </div>
                    <div className="mx-2 mb-4 col-3 box-info shadow-sm" onClick={()=>getAllRecruiters(2)}>
                        <h3>Total Premium Recruiters</h3>
                        <h1>{headData.recruiterPremiumCount}</h1>
                    </div>
                </div>
                </>}
                <Button onClick={()=>props.history.push("/createrecruiter")} variant="contained" className="mx-3">Create Recruiter</Button>
                <div className='p-4' style={{ height: '80vh', width: '100%' }}>
                <DataGrid
                    rows={allRecruiters}
                    columns={columns2}
                    autoPageSize
                    checkboxSelection
                    onRowClick={(item,ev)=>props.history.push(`/recruiterdetail/${item.row._id}`)}
                />
                </div>
            </section>



             </div>
    </div>
    )
}
const columns2 = [
    { field: 'id', headerName: 'ID',width:20},
    //{ field: 'brand', headerName: 'Brand Name',valueGetter:(param)=>param.value.name,width:150},
    { field: 'fullName', headerName: 'Name',valueGetter:(param)=>param.row.fullName,width:200},
    { field: 'email', headerName: 'Email',valueGetter:(param)=>param.row.email,width:200},
    { field: 'userLocation', headerName: 'Location',valueGetter:(param)=>param.row.userLocation.city,width:150},
    { field: 'subscription', headerName: 'Subscription',valueGetter:(param)=>param.row.subscription.name,width:150},
    { field: 'mobileNo', headerName: 'Mobile No',valueGetter:(param)=>param.row.mobileNo,width:150},
    { field: 'companyName', headerName: 'Company Name',valueGetter:(param)=>param.row.companyName,width:150},
    {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}


  ];
  const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
  }
  export default connect(mapStateToProps)(Recruiters)
