import React from 'react'
import "./Subscription.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment'
import { Button, Chip, TextField } from '@mui/material';
import {useForm} from 'react-hook-form'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {connect} from 'react-redux'
import TwoBDialog from '../Utils/TwoBDialog'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
function CreateSubscription(props) {
    const [display,setDisplay]=React.useState(false)
    const {handleSubmit,formState:{errors},register,setValue} = useForm()
    const [type,setType] = React.useState("")
    const [singleFeature,setSingleFeature] = React.useState("")
    const [features,setFeatures] = React.useState([])
    const [open,setOpen]=React.useState(false)
    const [custom,setCustom] = React.useState(false)
    let detail = props.location.state;

    React.useEffect(()=>{
      if(detail.task==="edit" && detail.type==="recruiter"){
        setValue("name",detail.data.name)
        setValue("amount",detail.data.amount)
        setValue("jobPostings",detail.data.jobPostings)
        setValue("cvAccess",detail.data.cvAccess)
        setType(detail.data.timeline)
        setFeatures(detail.data.features)
        setCustom(detail.data.custom)
      }else if(detail.task==="edit" && detail.type==="candidate"){
        setValue("name",detail.data.name)
        setValue("amount",detail.data.amount)
        setType(detail.data.timeline)
        setFeatures(detail.data.features)
      }
    },[])
    const onSubmit = (data)=>{
        if(detail.type==="recruiter"){
            if(detail.task==="edit"){
                //edit recruiter subs
                axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/subscription/editSubscription`,{
                    "id":detail.data._id,
                    "object":{
                        "name":data.name,
                        "amount":data.amount,
                        "features":features,
                        "timeline":type,
                        "jobPostings":data.jobPostings,
                        "cvAccess":data.cvAccess,
                        subRecruiters:data.subRecruiters,
                        custom
                    }
                },{headers:{token:props.user.user}})
                .then(res=>{
                    console.log(res)
                    props.history.push('/subscription')
                })
            }else{
                //create recruiter subs
                axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/subscription/createSubscription`,{
                    "name":data.name,
                    "amount":data.amount,
                    "features":features,
                    "timeline":type,
                    "jobPostings":data.jobPostings,
                    "cvAccess":data.cvAccess,
                    subRecruiters:data.subRecruiters,
                    custom
                },{headers:{token:props.user.user}})
                .then(res=>{
                    console.log(res)
                    props.history.push('/subscription')
                })
            }
        }else{
            if(detail.task==="edit"){
                //edit candidate subs
                axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/candidateSubscription/editSubscription`,{
                    "id":detail.data._id,
                    "object":{
                        "name":data.name,
                        "amount":data.amount,
                        "features":features,
                        "timeline":type
                    }
                },{headers:{token:props.user.user}})
                .then(res=>{
                    console.log(res)
                    props.history.push('/subscription')
                })
            }else{
                //create candidate subs
                axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/candidateSubscription/createSubscription`,{
                    "name":data.name,
                    "amount":data.amount,
                    "features":features,
                    "timeline":type
                },{headers:{token:props.user.user}})
                .then(res=>{
                    console.log(res)
                    props.history.push('/subscription')
                })
            }
        }
    }

    const handleDelete = ()=>{
        if(detail.type==="recruiter"){
            axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/subscription/deleteSubscription`,{
                id:detail.data._id
            },{headers:{token:props.user.user}})
            .then(res=>{
                console.log(res)
                props.history.push('/subscription')
            })
        }else{
            axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/candidateSubscription/deleteSubscription`,{
                id:detail.data._id
            },{headers:{token:props.user.user}})
            .then(res=>{
                console.log(res)
                props.history.push('/subscription')
            })
        }
    }

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 p-0">
            <Dashhead id={6} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container p-0" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>


            <section className="home-container px-5 py-2 createsubs">
            <TwoBDialog 
            open={open}
            setOpen={setOpen}
            title="Delete Subscription"
            description="Are you sure you want to delete this subscription"
            leftButton="Cancel"
            rightButton="Delete"
            handleSubmit={handleDelete}
            />
            <h1>{props.location.state.task} {props.location.state.type} subscription</h1>

            {detail.type==="recruiter"?<>
            <form onSubmit = {handleSubmit(onSubmit)} className="mr-5">
            <TextField className="mt-2 mb-4" fullWidth variant="outlined" id="outlined-basic" label="Name" {...register('name',{required:true})} />
            <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Subscription Timeline</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="Job Param Type"
                    onChange={(e)=>setType(e.target.value)}
                    >
                    <MenuItem value={'Monthly'}>Monthly</MenuItem>
                    <MenuItem value={'Quarterly'}>Quarterly</MenuItem>
                    <MenuItem value={'Yearly'}>Yearly</MenuItem>
                    </Select>
                </FormControl>
                <TextField className="mt-4 mb-4" fullWidth variant="outlined" id="outlined-basic" label="Amount" {...register('amount',{required:true})} />
                <TextField className="mt-2 mb-4" fullWidth variant="outlined" id="outlined-basic" label="Job Postings" {...register('jobPostings',{required:true})} />
                <TextField className="mt-2 mb-4" fullWidth variant="outlined" id="outlined-basic" label="CV Access" {...register('cvAccess',{required:true})} />
                <TextField className="mt-2 mb-4" fullWidth variant="outlined" id="outlined-basic" label="Sub Recruiters" {...register('subRecruiters',{required:true})} />
                <div className="row m-auto align-items-center">
                <div className="col-8">
                <TextField className="mt-2 mb-4" fullWidth variant="outlined" id="outlined-basic" label="Features" onChange={(e)=>setSingleFeature(e.target.value)} />
                </div>
                <div className="col-4">
                <Button onClick={()=>{
                    if(!features.includes(singleFeature)){
                        setFeatures([...features,singleFeature])
                    }
                }} variant="contained">Add Feature</Button>
                </div>
                </div>

                {
                    features.map((item,index)=><Chip className="m-3" key={index} label={item} onDelete={()=>{
                        setFeatures(features.filter(i=>i!==item))
                    }} />)
                }

                <FormGroup >
                    <FormControlLabel value={custom} onChange={(e)=>setCustom(e.target.checked)} control={<Checkbox  />} label="Custom" />
                </FormGroup>

                <Button variant="contained" fullWidth type="submit">{props.location.state.task} subscription</Button>
                {detail.task==="edit"&&
                <>
                {/* <h2>Or</h2> */}
                {/* <Button onClick={()=>setOpen(true)} variant="outlined" fullWidth color="error">Delete Subscription</Button> */}
                </>
                }

            </form>
            </>:
            <form onSubmit = {handleSubmit(onSubmit)} className="mr-5">
            <TextField className="mt-2 mb-4" fullWidth variant="outlined" id="outlined-basic" label="Name" {...register('name',{required:true})} />
            <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Subscription Timeline</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="Job Param Type"
                    onChange={(e)=>setType(e.target.value)}
                    >
                    <MenuItem value={'Monthly'}>Monthly</MenuItem>
                    <MenuItem value={'Quarterly'}>Quarterly</MenuItem>
                    <MenuItem value={'Yearly'}>Yearly</MenuItem>
                    </Select>
                </FormControl>
                <TextField className="mt-2 mb-4" fullWidth variant="outlined" id="outlined-basic" label="Amount" {...register('amount',{required:true})} />
                <div className="row m-auto align-items-center">
                <div className="col-8">
                <TextField className="mt-2 mb-4" fullWidth variant="outlined" id="outlined-basic" label="Features" onChange={(e)=>setSingleFeature(e.target.value)} />
                </div>
                <div className="col-4">
                <Button onClick={()=>{
                    if(!features.includes(singleFeature)){
                        setFeatures([...features,singleFeature])
                    }
                }} variant="contained">Add Feature</Button>
                </div>
                </div>

                {
                    features.map((item,index)=><Chip className="m-3" key={index} label={item} onDelete={()=>{
                        setFeatures(features.filter(i=>i!==item))
                    }} />)
                }
                <Button variant="contained" fullWidth type="submit">{props.location.state.task} subscription</Button>
            </form>
            }
            
            </section>



             </div>
    </div>
    )
}
const columns2 = [
    { field: 'id', headerName: 'ID',width:20},
    //{ field: 'brand', headerName: 'Brand Name',valueGetter:(param)=>param.value.name,width:150},
    { field: 'name', headerName: 'Name',valueGetter:(param)=>param.row.name,width:200},
    { field: 'amount', headerName: 'Amount',valueGetter:(param)=>param.row.amount,width:200},
    { field: 'jobPostings', headerName: 'Job Postings',valueGetter:(param)=>param.row.jobPostings,width:150},
    { field: 'cvAccess', headerName: 'CV Access',valueGetter:(param)=>param.row.cvAccess,width:150},
    { field: 'timeline', headerName: 'Timeline',valueGetter:(param)=>param.row.timeline,width:150},
    {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}


  ];
  const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
  }
  export default connect(mapStateToProps)(CreateSubscription)
