import React from 'react'
import "./JobParams.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios'
import moment from 'moment'
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import {connect} from 'react-redux'
function JobParams(props) {
    const [display,setDisplay]=React.useState(false)
    const [jobParams,setJobParams] = React.useState([])

    React.useEffect(()=>{
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getAllJobParam`,{headers:{token:props.user.user}})
        .then(res=>{
            let arr = res.data.result.map((item,index)=>({...item,id:index+1}))
            setJobParams(arr)
        })
    },[])
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 p-0">
            <Dashhead id={5} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container p-0" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>


            <section className="home-container p-4">
                <h1 className="">Job Params</h1>
                <h2>Product</h2>                
                <Button onClick={()=>props.history.push("/crudjobparam","product")} className="mb-4" variant="contained">Create Product</Button>
                <div className='' style={{ height: '40vh', width: '100%' }}>
                <DataGrid
                    rows={jobParams.filter((item)=>item.type==="product")}
                    columns={columns2}
                    autoPageSize
                    checkboxSelection
                    onRowClick={(item,ev)=>props.history.push(`/crudjobparam`,item.row)}
                />
                </div>
                <h2>Functional Area</h2>
                <Button onClick={()=>props.history.push("/crudjobparam","functionalarea")} className="mb-4" variant="contained">Create Functional Area</Button>
                <div className='' style={{ height: '40vh', width: '100%' }}>
                <DataGrid
                    rows={jobParams.filter((item)=>item.type==="functionalarea")}
                    columns={columns2}
                    autoPageSize
                    checkboxSelection
                    onRowClick={(item,ev)=>props.history.push(`/crudjobparam`,item.row)}
                />
                </div>
                <h2>Industry</h2>
                <Button onClick={()=>props.history.push("/crudjobparam","industry")} className="mb-4" variant="contained">Create Industry</Button>
                <div className='' style={{ height: '40vh', width: '100%' }}>
                <DataGrid
                    rows={jobParams.filter((item)=>item.type==="industry")}
                    columns={columns2}
                    autoPageSize
                    checkboxSelection
                    onRowClick={(item,ev)=>props.history.push(`/crudjobparam`,item.row)}
                />
                </div>
                <h2>UG Qualification</h2>
                <Button onClick={()=>props.history.push("/crudjobparam","ug")} className="mb-4" variant="contained">Create UG Qualification</Button>
                <div className='' style={{ height: '40vh', width: '100%' }}>
                <DataGrid
                    rows={jobParams.filter((item)=>item.type==="ug")}
                    columns={columns2}
                    autoPageSize
                    checkboxSelection
                    onRowClick={(item,ev)=>props.history.push(`/crudjobparam`,item.row)}
                />
                </div>
                <h2>PG Qualification</h2>
                <Button onClick={()=>props.history.push("/crudjobparam","pg")} className="mb-4" variant="contained">Create PG Qualification</Button>
                <div className='' style={{ height: '40vh', width: '100%' }}>
                <DataGrid
                    rows={jobParams.filter((item)=>item.type==="pg")}
                    columns={columns2}
                    autoPageSize
                    checkboxSelection
                    onRowClick={(item,ev)=>props.history.push(`/crudjobparam`,item.row)}
                />
                </div>
                
            </section>



             </div>
    </div>
    )
}
const columns2 = [
    { field: 'id', headerName: 'ID',width:20},
    //{ field: 'brand', headerName: 'Brand Name',valueGetter:(param)=>param.value.name,width:150},
    { field: 'name', headerName: 'Name',valueGetter:(param)=>param.row.name,width:100},
    { field: 'type', headerName: 'Type',valueGetter:(param)=>param.row.type,width:200},
    { field: '_id', headerName: '_id',valueGetter:(param)=>param.row._id,width:200},
    {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}


  ];
  const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
  }
  export default connect(mapStateToProps)(JobParams)
