import React from 'react'
import "./Candidates.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import {Button} from '@mui/material'
import { styled } from '@mui/material/styles';
import moment from 'moment'
import {connect} from 'react-redux'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import readXlsxFile from 'read-excel-file'
import date from 'date-and-time';
const Input = styled('input')({
    display: 'none',
  });
function Candidates(props) {
    const [display,setDisplay]=React.useState(false)
    const [allCandidates,setAllCandidates] = React.useState([])
    const [headData,setHeadData] = React.useState(null)
    const [flag,setFlag]=React.useState(false)

    const getAllCandidates = async (count)=>{
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getAllCandidates`,{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            if(res.data.result.length>0){
                let arr = res.data.result.map((item,index)=>({...item,id:index+1}))
                if(count === 1){
                    setAllCandidates(arr.filter(i=>i.subscription.name==="Free"))
                }else if(count === 2){
                    setAllCandidates(arr.filter(i=>i.subscription.name!=="Free"))

                }else if(count === 3){
                    setAllCandidates(arr.filter(i=>i.fresher===true))

                }else if(count === 4){
                    setAllCandidates(arr.filter(i=>i.fresher===false))
                }else{
                    setAllCandidates(arr)
                }
                
            }
        })
    }

    React.useEffect(()=>{
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getCandidateCardData`,{headers:{token:props.user.user}})
        .then(res=>{
               console.log(res) 
               setHeadData(res.data.result)
        })
        getAllCandidates()
    },[flag])
    const upload = (e)=>{
        console.log(e.target.files[0])
        readXlsxFile(e.target.files[0]).then((rows) => {
            console.log(rows)
            let candidateArray = rows.map((item,index)=>{
            let candidateObj = {}
                if(index!==0){
                    candidateObj.fullName=item[0]
                    candidateObj.email=item[1]
                    candidateObj.password=item[2]
                    candidateObj.mobileNo=item[3]
                    candidateObj.fresher=item[4]
                    candidateObj.resumeTagline=item[5]
                    candidateObj.gender=item[6]
                    candidateObj.userLocation={}
                    candidateObj.userLocation.state=item[7]
                    candidateObj.userLocation.city=item[8]
                    candidateObj.userLocation.country="India"
                    candidateObj.noticePeriod=item[9]
                    candidateObj.yearsOfExperience=item[10]
                    candidateObj.currentCtc=item[11]
                    candidateObj.product=item[12]
                    candidateObj.workExperience=[{name:item[13],designation:item[14],description:item[15],startDate:date.parse(item[16], 'YYYY/MM/DD'),endDate:item[17]?date.parse(item[17], 'YYYY/MM/DD'):"",current:item[18]}]
                    candidateObj.education=[{name:item[19],universityName:item[20],startDate:date.parse(item[21], 'YYYY/MM/DD'),endDate:item[22]?date.parse(item[22], 'YYYY/MM/DD'):null,current:item[23],featuredEducation:item[24]}]
                }
                return candidateObj
            })
            candidateArray = candidateArray.filter((i,ind)=>ind!==0)
            console.log("truedata",candidateArray)
            axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/addExcelCandidate`,{array:candidateArray},{headers:{token:props.user.user}})
            .then(res=>{
                console.log(res)
                setFlag(!flag)
            })
            // `rows` is an array of rows
            // each row being an array of cells.
          })
    }
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 p-0">
            <Dashhead id={3} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container p-0" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>


            <section className="home-container">
                {headData&&<>
                <div className="row m-auto justify-content-around box-parent">
                    <div className="mx-2 mb-4 col-3 box-info shadow-sm" onClick={()=>getAllCandidates()}>
                        <h3>Total Candidates</h3>
                        <h1>{headData.totalCount}</h1>
                    </div>
                    <div className="mx-2 mb-4 col-3 box-info shadow-sm" onClick={()=>{
                        getAllCandidates(1)
                        }}>
                        <h3>Total Free Candidates</h3>
                        <h1>{headData.freeCandidateCount}</h1>
                    </div>
                    <div className="mx-2 mb-4 col-3 box-info shadow-sm" onClick={()=>{
                        getAllCandidates(2)
                        }}>
                        <h3>Total Premium Candidates</h3>
                        <h1>{headData.totalCount - headData.freeCandidateCount}</h1>
                    </div>
                    <div className="mx-2 mb-4 col-3 box-info shadow-sm" onClick={()=>{
                        getAllCandidates(3)
                        }}>
                        <h3>Total Fresher Candidates</h3>
                        <h1>{headData.fresherCount}</h1>
                    </div>
                    <div className="mx-2 mb-4 col-3 box-info shadow-sm" onClick={()=>{
                        getAllCandidates(4)
                        }}>
                        <h3>Total Experienced Candidates</h3>
                        <h1>{headData.totalCount - headData.fresherCount}</h1>
                    </div>
                </div>
                </>}

                <Button onClick={()=>props.history.push("/createcandidate")} variant="contained" className="mx-3">Create Candidate</Button>
                        <label htmlFor="contained-button-file3">
                    <Input 
                    onChange={upload}
                    accept="application/*" id="contained-button-file3" multiple type="file" />
                    <Button component="span" endIcon={<UploadFileIcon />}>
                    Upload Candidate Excel
                    </Button>
                    </label>

                <div className='p-4' style={{ height: '80vh', width: '100%' }}>
                <DataGrid
                    rows={allCandidates}
                    columns={columns2}
                    autoPageSize
                    checkboxSelection
                    onRowClick={(item,ev)=>props.user.userInfo.type===1?props.history.push(`/candidatedetail/${item.row._id}`):null}
                    // onRowClick={(item,ev)=>props.history.push(`/candidatedetail/${item.row._id}`)}
                />
                </div>


            </section>


             </div>
    </div>
    )
}
const columns2 = [
    { field: 'id', headerName: 'ID',width:20},
    //{ field: 'brand', headerName: 'Brand Name',valueGetter:(param)=>param.value.name,width:150},
    { field: 'fullName', headerName: 'Name',valueGetter:(param)=>param.row.fullName,width:200},
    { field: 'email', headerName: 'Email',valueGetter:(param)=>param.row.email,width:200},
    { field: 'userLocation', headerName: 'Location',valueGetter:(param)=>param.row.userLocation.city,width:150},
    { field: 'subscription', headerName: 'Subscription',valueGetter:(param)=>param.row.subscription?param.row.subscription.name:"",width:150},
    { field: 'mobileNo', headerName: 'Mobile No',valueGetter:(param)=>param.row.mobileNo,width:150},
    { field: 'hiringRate', headerName: 'Hiring Rate',valueGetter:(param)=>param.row.hiringRate,width:150},
    { field: 'fresher', headerName: 'Fresher',valueGetter:(param)=>param.row.fresher,width:150},
    {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}


  ];
  const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
}
export default connect(mapStateToProps)(Candidates)
