import React from 'react'
import "./Jobs.scss"
import {IconButton,Button} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment'
import {connect} from 'react-redux'
function Jobs(props) {
    const [display,setDisplay]=React.useState(false)
    const [allJobs,setAllJobs] = React.useState([])
    const [headData,setHeadData] = React.useState(null)
    React.useEffect(()=>{
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getJobCardData`,{headers:{token:props.user.user}})
        .then(res=>{
               console.log(res) 
               setHeadData(res.data.result)
        })
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getAllJobs`,{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            if(res.data.result.length>0){
                let arr = res.data.result.map((item,index)=>({...item,id:index+1}))
                setAllJobs(arr)
            }
        })
    },[])
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 p-0">
            <Dashhead id={2} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container p-0" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>


            <section className="home-container">
            {headData&&<>
                <div className="row m-auto justify-content-around box-parent">
                    <div className="mx-2 mb-4 col-3 box-info shadow-sm" onClick={()=>null}>
                        <h3>Total Jobs</h3>
                        <h1>{headData.totalCount}</h1>
                    </div>
                    <div className="mx-2 mb-4 col-3 box-info shadow-sm" onClick={()=>null}>
                        <h3>Total Active Jobs</h3>
                        <h1>{headData.activeJobs}</h1>
                    </div>
                    <div className="mx-2 mb-4 col-3 box-info shadow-sm" onClick={()=>null}>
                        <h3>Total Expired Jobs</h3>
                        <h1>{headData.totalCount - headData.activeJobs}</h1>
                    </div>
                </div>
                </>}

                <Button className="mx-4" variant="contained" onClick={()=>props.history.push("/createjob")}>Create Job</Button>

                <div className='p-4' style={{ height: '80vh', width: '100%' }}>
                <DataGrid
                    rows={allJobs}
                    columns={columns2}
                    autoPageSize
                    checkboxSelection
                    onRowClick={(item,ev)=>props.user.userInfo.type===1?props.history.push(`/jobdetail/${item.row._id}`):null}
                />
                </div>
            </section>


             </div>
    </div>
    )
}
const columns2 = [
    { field: 'id', headerName: 'ID',width:20},
    //{ field: 'brand', headerName: 'Brand Name',valueGetter:(param)=>param.value.name,width:150},
    { field: 'title', headerName: 'Job Title',valueGetter:(param)=>param.row.title,width:250},
    { field: 'companyName', headerName: 'Company Name',valueGetter:(param)=>param.row.companyName,width:200},
    { field: 'jobLocation', headerName: 'Location',valueGetter:(param)=>param.row.jobLocation.city,width:150},
    { field: 'product', headerName: 'Product',valueGetter:(param)=>param.row.product,width:150},
    { field: 'industry', headerName: 'Industry',valueGetter:(param)=>param.row.industry,width:150},
    { field: 'functionalArea', headerName: 'Functional Area',valueGetter:(param)=>param.row.functionalArea,width:150},
    {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}


  ];
  const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
  }
  export default connect(mapStateToProps)(Jobs)
