import React from 'react'
import "./Candidates.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment'
import {Link, useParams} from 'react-router-dom'
import {getAge} from '../Utils/Functions'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PublishIcon from '@mui/icons-material/Publish';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {Button, Chip} from '@mui/material'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import WorkIcon from '@mui/icons-material/Work';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ArticleIcon from '@mui/icons-material/Article';
import DescriptionIcon from '@mui/icons-material/Description';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import TabPanel from '@mui/lab/TabPanel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {connect} from 'react-redux'
import TwoBDialog from '../Utils/TwoBDialog'
function CandidateDetail(props) {
    const [display,setDisplay]=React.useState(false)
    const [data,setData] = React.useState(null)
    const [value, setValue] = React.useState('1');
    const [plans,setPlans] = React.useState([])
    const [flag,setFlag] = React.useState(false)
    const [currentPlan,setCurrentPlan] = React.useState('')
    const [open,setOpen] = React.useState(false)
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    let params = useParams();
    console.log(props)
    console.log(params)
    const getPlans = ()=>{
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/candidateSubscription/allSubscription`)
        .then(res=>{
          console.log(res)
          if(res.data.msg==="success"){
            setPlans(res.data.result)
            
          }
          
        })
        .catch(err=>{
          console.log(err)
        })
      }
    React.useEffect(()=>{
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getCandidateData`,{candidateId:params.id},{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            if(res.data.result){
                let pending = res.data.result.jobResponse.filter(item=>item.jobCandidates.status==="Pending")
                let hired = res.data.result.jobResponse.filter(item=>item.jobCandidates.status==="Hired")
                let rejected = res.data.result.jobResponse.filter(item=>item.jobCandidates.status==="Rejected")
                setCurrentPlan(res.data.result.candidate.subscription.name)
                setData({...res.data.result,pending,hired,rejected})
                getPlans()
            }
            
        })
        .catch(err=>{
            console.log(err)
        })
    },[flag])
    const renderEmployementString = (userInfo)=>{
        if(userInfo.fresher){
            return "Fresher"
        }else if(userInfo.workExperience.filter(i=>i.current===true).length>0){
            let strobj = userInfo.workExperience.filter(i=>i.current===true)[0]
            return `${strobj.designation} | ${strobj.name}`
        }else{
            return "Currently Unemployed"
        }
    }

    const handleSubmit = ()=>{
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/deleteCandidate`,{id:data.candidate._id},{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            setOpen(false)
            props.history.push("/candidates")
        })
    }

console.log(data)
    const renderPersonalInfo = ()=>{
        return  <>
        <Button onClick={()=>setOpen(true)} color="error" variant="outlined">Delete Candidate</Button>
        <Button onClick={()=>props.history.push("/createcandidate",data.candidate)} className="ml-3" variant="outlined">Edit Candidate</Button>
        <section className="candidate-info-section row m-auto shadow-sm">
        <div className="col-2 img-cont">
            <img src={data.candidate.profilePicture?`${process.env.REACT_APP_DEVELOPMENT}/api/image/${data.candidate.profilePicture}`:"/profile.png"} alt="profile" />
        </div>
        <div className="col-6 info-cont">
        <h1>{data.candidate.fullName}</h1>
        <p className="bold-text">{renderEmployementString(data.candidate)}</p>
                <p className="bold-text">{data.candidate.education.length>0?data.candidate.education.map(i=>{
                    if(i.featuredEducation){
                        return i.name + ', ' + i.universityName;
                    }
                }):"Add Featured Education"}</p>
            <p className="grey-text">{data.candidate.gender} | {data.candidate.dob?getAge(data.candidate.dob):"DOB Missing"}</p>
                <div className="row m-auto sub-info">
                <p className="mx-2"><LocalPhoneIcon sx={{marginRight:.1}} /> <b>{data.candidate.mobileNo}</b></p>
                    <p className="mx-2"><AlternateEmailIcon sx={{marginRight:.1}} /> <b>{data.candidate.email}</b></p>
                    <p className="mx-2"><LocationOnIcon sx={{marginRight:.1}} /> <b>{data.candidate.userLocation.city}, {data.candidate.userLocation.state}</b></p>
                </div>
                <p className="grey-text">{data.candidate.resumeTagline?data.candidate.resumeTagline:"Tagline Missing"}</p>
            <div className="row m-auto chip-div">
            {data.candidate.skills.length>0?data.candidate.skills.map((item,index)=><Chip label={item} key={index} className="mx-2" color="primary" />):<p style={{color:"red",fontWeight:"bold"}}>You haven't added any skills yet, add from the skills section below</p>}
            </div>
        </div>
        <div className="col-4 resume-div">
            <div className="resume-head shadow-sm">
                {data.candidate.resume?<div className="resume-child row m-auto">
                    <div className="col-2">
                        <InsertDriveFileIcon color="primary" />
                    </div>
                    <div className="col-10 underline-hover" onClick={()=>window.open(`${process.env.REACT_APP_DEVELOPMENT}/api/pdf/${data.candidate.resume}`, '_blank')}>
                        <h4>{data.candidate.fullName}'s Resume.{data.candidate.resume.split(".")[1]}</h4>
                        <p>Click to view resume</p>
                    </div>

                </div>:
                <div className="resume-child">
                <p>No resume</p>
            </div>
                }
            </div>

            <div className="subs-div align-items-center">
                <div className="m-1">
                    <h5>Currently on {data.candidate.subscription.name} Tier</h5>
                </div>
                <div className="m-2">
                <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Change Plan</FormLabel>
                <RadioGroup
                    value={currentPlan}
                    onChange={(e)=>setCurrentPlan(e.target.value)}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                >{
                    plans.map((item,index)=><FormControlLabel value={item.name} control={<Radio />} label={item.name} key={index} />)
                }
                    
                </RadioGroup>
                <Button onClick={()=>{
                    axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/changePlan`,{id:data.candidate._id,recruiter:false,subscriptionId:plans.filter(item=>item.name===currentPlan)[0]._id,plan:plans.filter(item=>item.name===currentPlan)[0]},{headers:{token:props.user.user}})
                    .then(res=>{
                        console.log(res)
                        setFlag(!flag)
                    })
                }}>Change Plan</Button>
                </FormControl>
                </div>
            </div>
        </div>
    </section>

    {/* <section className="row m-auto view-section-head justify-content-around">
        <div className="col-3 view-section-child row m-auto align-items-center justify-content-around shadow-sm">
            <div>
            <p>Total Profile Views</p>
            </div>

            <div className="ml-2">
                <h2>1465</h2>
            </div>
        </div>
        <div className="col-3 view-section-child row m-auto align-items-center justify-content-around shadow-sm">
            <div>
            <p>Total Profile Bookmarks</p>
            </div>

            <div className="ml-2">
                <h2>65</h2>
            </div>
        </div>
        <div className="col-3 view-section-child row m-auto align-items-center justify-content-around shadow-sm">
            <div>
            <p>Your Hiring Rate</p>
            </div>

            <div className="ml-2">
                <h2>45%</h2>
            </div>
        </div>
    </section> */}

    {/* view sections */}

    {/* profile info sections */}
    <section className="row m-auto info-sections justify-content-around">
        <div className="col-5 info-child shadow-sm">
            {/* heading of personal info */}
            <div className="row m-auto justify-content-between">
                <div>
                <h2>Personal Info</h2>
                </div>
                <div>
                    {/* <Button>Add Details</Button> */}
                </div>
            </div>

            {/* content of perosnal ifno */}
            <div className="info-sub-content">
                <div>
                <p className="key">Date of Birth</p>
                <p className="val">{moment.parseZone(data.candidate.dob).local().format("dddd, MMMM Do YYYY")}</p>
                </div>
            </div>
            <div className=" info-sub-content">
                <div>
                <p className="key">Languages</p>
                <p className="val">
                    {
                        data.candidate.languages.map((item,index)=><span key={index}>{item} | </span>)
                    }
                </p>
                </div>
            </div>
            <div className=" info-sub-content">
                <div>
                <p className="key">Marital Status</p>
                <p className="val">{data.candidate.maritalStatus}</p>
                </div>
            </div>
            <div className=" info-sub-content">
                <div>
                <p className="key">Category</p>
                <p className="val">{data.candidate.category}</p>
                </div>
            </div>
        </div>
        <div className="col-5 info-child shadow-sm">
            <div className="">
                <div>
                <h2>Employment</h2>
                </div>
            </div>
            {
                data.candidate.workExperience.length>0?
                data.candidate.workExperience.map((item,index)=>(
                    <div key={index} className=" info-sub-content">
                    <div className="p-0 col-12">
                    <p className="key">{item.designation} {item.name}</p>
                    <p className="val">{item.description}</p>
                    <p className="datefromto">{moment.parseZone(item.startDate).local().format("DD/MM/YYYY")} - {item.current?"Current":moment.parseZone(item.endDate).local().format("DD/MM/YYYY")}</p>
                    </div>
                </div>
                ))
                :<p><b>No Employment Details</b></p>
            }

        </div>
        <div className="col-5 info-child shadow-sm">
            <div className="">
                <div>
                <h2>Education</h2>
                </div>
            </div>
            {
                data.candidate.education.length>0?
                data.candidate.education.map((item,index)=>(
                    <div key={index} className=" info-sub-content">
                   <div className="p-0 col-8">
                    <p className="key">{item.name}</p>
                    <p className="val">{item.universityName}</p>
                    <p className="datefromto">{moment.parseZone(item.startDate).local().format("DD/MM/YYYY")} - {item.current?"Current":moment.parseZone(item.endDate).local().format("DD/MM/YYYY")}</p>
                    </div>
                </div>
                ))
                :<p><b>Add Education Details</b></p>
            }
        </div>
        <div className="col-5 info-child shadow-sm">
            <div className="">
                <div>
                <h2>Key Skills</h2>
                </div>
            </div>
            {
                data.candidate.skills.map((item,index)=><Chip className="m-2" color="primary" label={item} key={index} />)
            }
        </div>
        <div className="col-5 info-child shadow-sm">
            <div className="">
                <div>
                <h2>Preferences</h2>
                </div>
            </div>
            {
                data.candidate.preferences.map((item,index)=><Chip className="m-2" color="primary"label={item} key={index} />)
            }
        </div>

    </section>
    </>
    }
    const renderImageString = (createdBy)=>{
        console.log(createdBy)
        if(createdBy){
            if(Array.isArray(createdBy) && createdBy.length>0){
                if(createdBy[0].companyImg.length>0){
                    return `${process.env.REACT_APP_DEVELOPMENT}/api/image/${createdBy[0].companyImg}`
                }else{
                    return '/job-offer.png'
                }
                
            }else if(createdBy.companyImg){
                if(createdBy.companyImg.length>0){
                    return `${process.env.REACT_APP_DEVELOPMENT}/api/image/${createdBy.companyImg}`
                }else{
                    return '/job-offer.png'
                }
                
            }else{
                return '/job-offer.png'
            }
        }
    
    }
    const renderJobs = (item)=>{
        return <Link to={`/jobdetail/${item._id}`} className="link-underline">
        <section className={`col-12 shadow-sm job-apply-head row m-auto`}>
        <div className='img-div col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1'>
            {/* <img src={item.createdBy?`${process.env.REACT_APP_DEVELOPMENT}/api/image/${item.createdBy[0].companyImg}`:`${process.env.REACT_APP_DEVELOPMENT}/api/image/${item.createdByAdmin.companyImg}`} alt="logo1" /> */}
            <img src={item.createdByAdmin?renderImageString(item.createdByAdmin):renderImageString(item.createdBy)} alt="logo1" />
        </div>
        <div className='content-div col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9'>
            <h3 className="m-0">{item.title}</h3>
            <p className="m-0 companyName">{item.companyName}</p>
            <h4 className="m-0">{item.product}</h4>
                <div className='row mt-2 mx-auto align-items-center'>
                    <div>
                    <Rating name="read-only" value={3} readOnly />
                    </div>
                    <div>
                    <p className="total-reviews">(47 Reviews)</p>
                    </div>
                </div>
            <div className="row my-2 mx-auto key-features">
                <div className="m-1">
                    <WorkIcon />
                    <span className='key-headline m-2'>{item.experience.min} - {item.experience.max} Yrs</span>
                </div>
                <div className="m-1">
                    <CurrencyRupeeIcon />
                    <span className='key-headline m-2'>{item.ctc.min} - {item.ctc.max} P.A</span>
                </div>
                <div className="m-1">
                    <FmdGoodIcon />
                    <span className='key-headline m-2'>{item.jobLocation.city} | {item.jobLocation.state} | {item.jobLocation.country}</span>
                </div>
            </div>
            <div className="row my-2 mx-auto key-features">
                <div className="m-1">
                    <ArticleIcon />
                    <span className='key-headline m-2'>{item.qualification.ug} in CSE</span>
                </div>
                <div className="m-1">
                    <DescriptionIcon />
                    <span className='key-headline m-2'>{item.qualification.pg} in Finance</span>
                </div>
                <div className="m-1">
                    <Inventory2Icon />
                    <span className='key-headline m-2'>{item.industry}</span>
                </div>
            </div>



        </div>
    </section>
    </Link>
    }
    const renderPlanName = (id)=>{
        let plan = plans.filter(item=>item._id===id)[0]
        return plan.name;
      }
    const columns2 = [
        { field: 'id', headerName: 'ID',width:20},
        //{ field: 'brand', headerName: 'Brand Name',valueGetter:(param)=>param.value.name,width:150},
        { field: 'fullName', headerName: 'Name',valueGetter:(param)=>renderPlanName(param.row.subscriptionId),width:100},
        { field: 'expiry', headerName: 'Expiry',valueGetter:(param)=>param.row.expiry,width:200},
        { field: 'paymentId', headerName: 'Payment Id',valueGetter:(param)=>param.row.paymentId.razorpay_payment_id,width:200},
        { field: 'subscriptionId', headerName: 'Subscription ID',valueGetter:(param)=>param.row.subscriptionId,width:250},
        {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}
    
    
      ];
    return (
        <div className="row">
            <TwoBDialog 
            open={open} 
            setOpen={setOpen}
            title="Delete Candidate"
            description="Are you sure you want to delete this candidate"
            leftButton="Cancel"
            rightButton="Submit"
            handleSubmit={handleSubmit}
            />
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 p-0">
            <Dashhead id={3} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container p-0" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>


            {data&&<section className="home-container">
            <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Personal Info" value="1" />
                <Tab label="Payments" value="2" />
                <Tab label="Hired Jobs" value="3" />
                <Tab label="Rejected Jobs" value="4" />
                <Tab label="Pending Jobs" value="5" />
            </TabList>
            </Box>
            <TabPanel value="1">{renderPersonalInfo()}</TabPanel>
            <TabPanel value="2">
            <div className='p-4' style={{ height: '80vh', width: '100%' }}>
                <DataGrid
                    rows={data.candidate.paymentHistory.map((item,index)=>({...item,id:index+1}))}
                    columns={columns2}
                    autoPageSize
                    checkboxSelection
                    // onRowClick={(item,ev)=>props.history.push(`/recruiterdetail/${item.row._id}`)}
                />
                </div>
            </TabPanel>
            <TabPanel value="3">{data.hired.map((item)=>renderJobs(item))}</TabPanel>
            <TabPanel value="4">{data.rejected.map((item)=>renderJobs(item))}</TabPanel>
            <TabPanel value="5">{data.pending.map((item)=>renderJobs(item))}</TabPanel>
        </TabContext>
           
            </section>}


             </div>
    </div>
    )
}
const columns2 = [
    { field: 'id', headerName: 'ID',width:20},
    //{ field: 'brand', headerName: 'Brand Name',valueGetter:(param)=>param.value.name,width:150},
    { field: 'fullName', headerName: 'Name',valueGetter:(param)=>param.row.fullName,width:200},
    { field: 'email', headerName: 'Email',valueGetter:(param)=>param.row.email,width:200},
    { field: 'userLocation', headerName: 'Location',valueGetter:(param)=>param.row.userLocation.city,width:150},
    { field: 'subscription', headerName: 'Subscription',valueGetter:(param)=>param.row.subscription,width:150},
    { field: 'mobileNo', headerName: 'Mobile No',valueGetter:(param)=>param.row.mobileNo,width:150},
    { field: 'hiringRate', headerName: 'Hiring Rate',valueGetter:(param)=>param.row.hiringRate,width:150},
    {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}


  ];
  const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
}
export default connect(mapStateToProps)(CandidateDetail)
