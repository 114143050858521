import React from 'react'
import "./Candidates.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import {Button, TextField} from '@mui/material'
import moment from 'moment'
import {useForm} from 'react-hook-form'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Chip } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import {Autocomplete} from '@mui/material'
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PublishIcon from '@mui/icons-material/Publish';
import EditProfileImage from './EditProfileImage';
import {connect} from 'react-redux'

const Input = styled('input')({
    display: 'none',
  });
function CreateCandidate(props) {

    console.log("create candidate props",props)

    const [display,setDisplay]=React.useState(false)
    const {handleSubmit,register,formState:{errors},setValue}=useForm()
    const [radio,setRadio] = React.useState('true')
    const [dob, setDob] = React.useState('');
    const [states,setStates]=React.useState([])
    const [cities,setCities] = React.useState([])
    const [formValues,setFormValues] = React.useState({state:"",city:"",product:"",noticePeriod:""})
    const [gender, setGender] = React.useState('Male');
    const [products,setProducts]=React.useState([])
    const [resume,setResume]=React.useState(null)
    const [image,setImage] = React.useState(null)
    const [imageFile,setImageFile] = React.useState(null)
    const [open,setOpen] = React.useState(false)
    const [showEmployement,setShowEmployement] = React.useState(true)
    const [startDate, setStartDate] = React.useState(new Date('2014-08-18T21:11:54'));
    const [endDate,setEndDate] = React.useState(new Date(''))
    const [current,setCurrent]=React.useState(false)
    
    const [startDate2, setStartDate2] = React.useState(new Date('2014-08-18T21:11:54'));
    const [endDate2,setEndDate2] = React.useState(new Date('2014-08-18T21:11:54'))
    const [current2,setCurrent2]=React.useState(false)
    const [featuredEducation,setFeaturedEducation] = React.useState(false)
    const [allEducation,setAllEducation] = React.useState([])
    const [singleEducation,setSingleEducation] = React.useState(null)

    const handleChange = (event) => {
      setGender(event.target.value);
    };

    React.useEffect(()=>{
        if(props.location.state){
          let detail = props.location.state
          setValue("fullName",detail.fullName)
          setValue("email",detail.email)
          setValue("mobileNo",detail.mobileNo)
          setValue("resumeTagline",detail.resumeTagline)
          setValue("yearsOfExperience",detail.yearsOfExperience)
          setValue("currentCtc",detail.currentCtc)
          if(detail.fresher){
            setRadio("true")
          }else{
            setRadio("false")
          }
          setGender(detail.gender)
          setFormValues({
            ...formValues,
            state:detail.userLocation.state,
            city:detail.userLocation.city,
            noticePeriod:detail.noticePeriod,
            product:detail.product
          })
        }
            var config = {
              method: 'get',
              url: 'https://api.countrystatecity.in/v1/countries/IN/states',
              headers: {
                'X-CSCAPI-KEY': 'cGZ2TlJRcVBmejZ1OHRuOFRNZGd1ZENDa1hKbXVFVDlqVEFvdGxjUA=='
              }
            };
            
            axios(config)
            .then(function (response) {
              setStates(response.data)
            })
            .catch(function (error) {
            });
            axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getAllProducts`,{headers:{token:props.user.user}})
            .then(res=>{
              setProducts(res.data.result)
              axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getEducationJobParam`)
              .then(res=>{
                setAllEducation(res.data.result)
              })
            })
    },[])

    const upload = (e)=>{
        setResume(e.target.files[0])
    }

    const onSubmit = (data)=>{
        let employementObj = {
          name:data.companyName,
          designation:data.designation,
          description:data.description,
          startDate,
          endDate:current?"":endDate,
          current
        }
        let educationObj={
          name:singleEducation.name,
          startDate2,
          endDate:current2?"":endDate2,
          current:current2,
          featuredEducation,
          universityName:data.universityName
        }
        if(props.location.state){
          //edit candidate
          let detail = {...props.location.state};
          if(!data.password){
            data.password=null;
          }
          if(detail.education.length>0 && educationObj.current){
            detail.education = detail.education.map(i=>{
              if(i.current){
                i.current=false
              }
              return i;
            })
          }
          if(detail.education.length>0 && educationObj.featuredEducation){
            detail.education = detail.education.map(i=>{
              if(i.featuredEducation){
                i.featuredEducation=false
              }
              return i;
            })
          }
          if(detail.workExperience.length>0 && employementObj.current){
            detail.workExperience = detail.workExperience.map(i=>{
              if(i.current){
                i.current=false
              }
              return i;
            })
          }
          let finalObject = {...data,
            noticePeriod:formValues.noticePeriod,
            userLocation:{
              state:formValues.state,
              country:'India',
              city:formValues.city
            },
            education:[...detail.education,educationObj],
            workExperience:[...detail.workExperience,employementObj],
            gender}
          console.log(finalObject)
          axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/editCandidate`,{
            id:props.location.state._id,
            object:finalObject
          },{headers:{token:props.user.user}})
          .then(async res=>{
            console.log(res)
            if(imageFile){
              const formdata1 = new FormData();
              formdata1.append('file',imageFile)
              formdata1.append('candidateId',res.data.result._id)
              await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/upload-candidate-image`,formdata1,{headers:{Accept:'application/json','Content-Type':"multipart/form-data",token:props.user.user}})
            }
            if(resume){
              const formdata2 = new FormData();
              formdata2.append('file',resume)
              formdata2.append('candidateId',res.data.result._id)
              await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/upload-candidate-resume`,formdata2,{headers:{Accept:'application/json','Content-Type':"multipart/form-data",token:props.user.user}})
            }
            props.history.push("/candidates")
          })
        }else{
          if(imageFile && resume){
            const formdata1 = new FormData();
            formdata1.append('file',imageFile)
            const formdata2 = new FormData();
            formdata2.append('file',resume)
            axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/createCandidate`,{
              ...data,
              noticePeriod:formValues.noticePeriod,
              userLocation:{
                state:formValues.state,
                country:'India',
                city:formValues.city
              },
              education:educationObj,
              workExperience:employementObj,
              gender
            },{headers:{token:props.user.user}})
            .then(async res=>{
              console.log(res)
              if(res.data.msg==="success"){
                formdata1.append('candidateId',res.data.result._id)
                formdata2.append('candidateId',res.data.result._id)
                await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/upload-candidate-image`,formdata1,{headers:{Accept:'application/json','Content-Type':"multipart/form-data",token:props.user.user}})
                await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/upload-candidate-resume`,formdata2,{headers:{Accept:'application/json','Content-Type':"multipart/form-data",token:props.user.user}})
                props.history.push("/candidates")
              }
              
  
            })
          }else{
            //setError
          }
        }
    }
    const getCities = (state)=>{
        var config = {
            method: 'get',
            url: `https://api.countrystatecity.in/v1/countries/IN/states/${state}/cities`,
            headers: {
              'X-CSCAPI-KEY': 'cGZ2TlJRcVBmejZ1OHRuOFRNZGd1ZENDa1hKbXVFVDlqVEFvdGxjUA=='
            }
          };
          
          axios(config)
          .then(function (response) {
            setCities(response.data)
          })
          .catch(function (error) {
          });
    }
    const imageSubmit = (image,file)=>{
        setImage(image)
        setImageFile(file)
    }

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 p-0">
            <Dashhead id={3} display={display} />
            </div>

            <EditProfileImage
            open={open}
            setOpen={setOpen}
            handleSubmit = {imageSubmit}
            />
            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container p-0" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>


            <section className="home-container">
               
            <form className="create-form" onSubmit = {handleSubmit(onSubmit)}>
            <h1>{props.location.state?"Edit Candidate":"Create Candidate"}</h1>
            <TextField variant="outlined" className="my-3" id="outlined-basic" label="FullName" {...register("fullName",{required:true})} fullWidth />
            <TextField variant="outlined" className="my-3" id="outlined-basic" label="Email" {...register("email",{required:true})} fullWidth />
            <TextField variant="outlined" className="my-3" id="outlined-basic" label="Password" {...register("password",{required:false})} fullWidth />
            <TextField variant="outlined" className="my-3" id="outlined-basic" label="Mobile Number" {...register("mobileNo",{required:true})} fullWidth />
            <FormControl>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                onChange={(e)=>setRadio(e.target.value)}
                value={radio}
                row
            >
                <FormControlLabel value={'false'} control={<Radio />} label="Experienced (have work experience)" />
                <FormControlLabel value={'true'} control={<Radio />} label="Fresher (haven't worked after grad" />
            </RadioGroup>
            </FormControl>
            <TextField
          className="mb-3"
          inputProps={{ maxLength: 200 }}
          {...register("resumeTagline",{required:true})}
          error={errors.resumeTagline?true:false}
            autoFocus
            margin="dense"
            id={'outlined-basic'}
            label={"Resume Tagline"}
            fullWidth
            variant="outlined"
          />

<FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={gender}
        row
        onChange={handleChange}
      >
        <FormControlLabel value="Male" control={<Radio />} label="Male" />
        <FormControlLabel value="Female" control={<Radio />} label="Female" />
      </RadioGroup>
    </FormControl>

                    <div className="my-4">
                    <Autocomplete
                    fullWidth
                    onChange={(event, newValue) => {
                    setFormValues({...formValues,state:newValue.name});
                    getCities(newValue.iso2)
                    }}
                    id="controllable-states-demo"
                    options={states}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Select State"/>}
                    />
                    {props.location.state&&<p className="mt-2"><b>Current Data: {props.location.state.userLocation.state}</b></p>}
                    </div>
                    <div className="my-4">
                    <Autocomplete
                    fullWidth
                    onChange={(event, newValue) => {
                    setFormValues({...formValues,city:newValue.name});
                    }}
                    id="controllable-states-demo"
                    options={cities}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Select City"/>}
                    />
                    {props.location.state&&<p className="mt-2"><b>Current Data: {props.location.state.userLocation.city}</b></p>}
                    </div>

                    <div className="my-4">
                    <Autocomplete
                    fullWidth
                    value={formValues.noticePeriod}
                    onChange={(event, newValue) => {
                    setFormValues({...formValues,noticePeriod:newValue});
                    }}
                    id="controllable-states-demo"
                    options={['7 Days','30 Days','60 Days','90 Days','Immediate Joiner','Currently Serving Notice Period']}
                    // getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Choose Notice Period"/>}
                    />
                    {props.location.state&&<p className="mt-2"><b>Current Data: {props.location.state.noticePeriod}</b></p>}
                    </div>

                    {/* <TextField
                    className="mb-3"
                    inputProps={{ maxLength: 200 }}
                    {...register("noticePeriod",{required:true})}
                    error={errors.noticePeriod?true:false}
                      autoFocus
                      margin="dense"
                      id={'outlined-basic'}
                      label={"Notice Period (in Weeks)"}
                      fullWidth
                      variant="outlined"
                    /> */}

                    <TextField
                    className="mb-3"
                    inputProps={{ maxLength: 200 }}
                    {...register("yearsOfExperience",{required:true})}
                    error={errors.yearsOfExperience?true:false}
                      autoFocus
                      margin="dense"
                      id={'outlined-basic'}
                      label={"Years of Experience"}
                      fullWidth
                      variant="outlined"
                    />
                    <TextField
                    className="mb-3"
                    inputProps={{ maxLength: 200 }}
                    {...register("currentCtc",{required:true})}
                    error={errors.currentCtc?true:false}
                      autoFocus
                      margin="dense"
                      id={'outlined-basic'}
                      label={"Current CTC (In Numbers)"}
                      fullWidth
                      variant="outlined"
                    />

                    <div className="my-4">
                    <Autocomplete
                    fullWidth
                    onChange={(event, newValue) => {
                    setFormValues({...formValues,product:newValue.name});
                    }}
                    id="controllable-states-demo"
                    options={products}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Select Product"/>}
                    />
                    {props.location.state&&<p className="mt-2"><b>Current Data: {props.location.state.product}</b></p>}
                    </div>
                    {resume?<div className="resume-child row m-auto">
                            <div className="col-2">
                                <InsertDriveFileIcon color="primary" />
                            </div>
                            <div className="col-8 underline-hover" onClick={()=>window.open(`${process.env.REACT_APP_DEVELOPMENT}/api/pdf/${resume}`, '_blank')}>
                                <h4>{resume.name}</h4>
                            </div>
                            <div className="col-2">
                                <IconButton onClick={()=>setResume(null)} component="span">
                                <PublishIcon sx={{fontSize:30}} color="tertiary" />
                                </IconButton>
                            </div>
                        </div>:
                        <div className="resume-child row m-auto">
                        <div className="" style={{margin:"auto"}}>
                        <label htmlFor="contained-button-file3">
                    <Input 
                    onChange={upload}
                    accept="application/*" id="contained-button-file3" multiple type="file" />
                    <Button variant="contained" component="span" endIcon={<UploadFileIcon />}>
                    Upload Resume
                    </Button>
                    </label>
                        </div>
                        
                    </div>
                        }
                        {!image&&<Button onClick={()=>setOpen(true)}>Upload Image</Button>}
                        <div>
                        {
                            image&&<img src={image} alt="profileimage" />
                        }
                        </div>
                        {image&&<Button onClick={()=>setImage(null)}>Delete Image</Button>}
                        <FormGroup>
                        <FormControlLabel onChange={(e)=>setShowEmployement(e.target.checked)} control={<Checkbox defaultChecked />} label="Employed" />
                        </FormGroup>
                        {
                            radio==="false"&&<>
                        <h1>Add Employement</h1>
                            <TextField
                            className="mb-3"
                            inputProps={{ maxLength: 200 }}
                            {...register("companyName",{required:true})}
                            error={errors.companyName?true:false}
                                margin="dense"
                                id={'outlined-basic'}
                                label={"Company Name"}
                                fullWidth
                                variant="outlined"
                            />
                            <TextField
                            className="mb-3"
                            inputProps={{ maxLength: 200 }}
                            {...register("designation",{required:true})}
                            error={errors.designation?true:false}
                                margin="dense"
                                id={'outlined-basic'}
                                label={"Designation"}
                                fullWidth
                                variant="outlined"
                            />
                            <TextField
                            className="mb-3"
                            inputProps={{ maxLength: 200 }}
                            {...register("description",{required:true})}
                            error={errors.description?true:false}
                                margin="dense"
                                id={'outlined-basic'}
                                label={"Company Description"}
                                fullWidth
                                variant="outlined"
                            />

                            <section className="my-3">
                            <LocalizationProvider 
                            
                            dateAdapter={AdapterDateFns} >
                            <DesktopDatePicker
                            label="Start Date"
                            inputFormat="dd/MM/yyyy"
                            value={startDate}
                            onChange={(newVal)=>setStartDate(newVal)}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                        </LocalizationProvider>
                        </section>

                        <section className="my-3">
                        
                        <LocalizationProvider 
                            dateAdapter={AdapterDateFns} >
                            <DesktopDatePicker
                            label="End Date"
                            inputFormat="dd/MM/yyyy"
                            disabled={current?true:false}
                            value={endDate}
                            onChange={(newVal)=>setEndDate(newVal)}
                            renderInput={(params) => <TextField  fullWidth {...params} />}
                        />
                        </LocalizationProvider>
                        </section>
                        <FormGroup>
                        <FormControlLabel control={<Checkbox checked={current} onChange={(e)=>setCurrent(e.target.checked)} />} label="Currently Employed" />
                        </FormGroup>
                            </>
                        }

                        <h1>Add Education</h1>

{<>
            <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={allEducation}
            fullWidth
            className='mb-3'
            onChange={(e,value)=>setSingleEducation(value)}
            getOptionLabel={(item)=>item.name}
            renderInput={(params) => <TextField  {...params} label="Degree Name" />}
          />
          {/* <TextField
          className="mb-3"
          inputProps={{ maxLength: 200 }}
          {...register("name",{required:true})}
          error={errors.name?true:false}
            margin="dense"
            id={'outlined-basic'}
            label={"Degree Name"}
            fullWidth
            variant="outlined"
          /> */}
          <TextField
          className="mb-3"
          inputProps={{ maxLength: 200 }}
          {...register("universityName",{required:true})}
          error={errors.universityName?true:false}
            margin="dense"
            id={'outlined-basic'}
            label={"University Name"}
            fullWidth
            variant="outlined"
          />

        <section className="my-3">
        <LocalizationProvider 
        
        dateAdapter={AdapterDateFns} >
        <DesktopDatePicker
        label="Start Date"
        inputFormat="dd/MM/yyyy"
        value={startDate2}
        onChange={(newVal)=>setStartDate2(newVal)}
        renderInput={(params) => <TextField fullWidth {...params} />}
      />
      </LocalizationProvider>
      </section>

      <section className="my-3">
      
      <LocalizationProvider 
        dateAdapter={AdapterDateFns} >
        <DesktopDatePicker
        label="End Date"
        inputFormat="dd/MM/yyyy"
        disabled={current2?true:false}
        value={endDate2}
        onChange={(newVal)=>setEndDate2(newVal)}
        renderInput={(params) => <TextField  fullWidth {...params} />}
      />
      </LocalizationProvider>
      </section>

    <p className="grey-text">Note: if you are pursuing your education then click on the checkbox given below</p>

      <FormGroup>
      <FormControlLabel control={<Checkbox checked={current2} onChange={(e)=>setCurrent2(e.target.checked)} />} label="Currently Studying" />
      <FormControlLabel control={<Checkbox checked={featuredEducation} onChange={(e)=>setFeaturedEducation(e.target.checked)} />} label="Featured Education" />
      </FormGroup>
          </>}

                    {/* <section className="my-3">
                    <LocalizationProvider 
                    
                    dateAdapter={AdapterDateFns} >
                    <DesktopDatePicker
                    label="Date of Birth"
                    inputFormat="dd/MM/yyyy"
                    value={dob}
                    onChange={(newVal)=>setDob(newVal)}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                />
                </LocalizationProvider>
                </section> */}
            <div>
            <Button variant="contained" type="submit">{props.location.state?"Edit Candidate":"Create Candidate"}</Button>
            </div>
            </form>

            </section>


             </div>
    </div>
    )
}
const mapStateToProps = ({EventUser})=>{
  return {
      user:EventUser
  }
}
export default connect(mapStateToProps)(CreateCandidate)
