import React from 'react'
import "./Blogs.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios'
import moment from 'moment'
import { DataGrid } from '@mui/x-data-grid';
import { Button, TextField } from '@mui/material';
import {connect} from 'react-redux'
import {useForm} from 'react-hook-form'
import EditProfileImage from '../Candidates/EditProfileImage';
import TwoBDialog from '../Utils/TwoBDialog'
function CreateBlog(props) {
    const [display,setDisplay]=React.useState(false)
    const {handleSubmit,register,setValue,formState:{errors}}=useForm()
    //const [featuredData,setFeaturedData] = React.useState([])
    const [image,setImage] = React.useState(null)
    const [imageFile,setImageFile] = React.useState(null)
    const [open,setOpen] = React.useState(false)
    const [open2,setOpen2] = React.useState(false)
    React.useEffect(()=>{
        if(props.location.state){
            setValue("title",props.location.state.title)
            setValue("description",props.location.state.description)
            setImage(`${process.env.REACT_APP_DEVELOPMENT}/api/image/${props.location.state.img}`)
        }
    },[])

    const onSubmit =(data)=>{
        console.log(data)
        if(props.location.state){
            axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/editBlog`,{id:props.location.state._id,object:{...data}},{headers:{token:props.user.user}})
            .then(async res=>{
                console.log(res)
                if(imageFile){
                    const formdata1 = new FormData();
                    formdata1.append('file',imageFile)
                    formdata1.append('blogId',props.location.state._id)
                    formdata1.append('img',props.location.state.img)
                    await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/upload-blog-img`,formdata1,{headers:{Accept:'application/json','Content-Type':"multipart/form-data",token:props.user.user}})

                }
                props.history.push("/blogs")

            })
        }else{
            axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/createBlog`,{...data},{headers:{token:props.user.user}})
            .then(res=>{
                console.log(res)
                const formdata1 = new FormData();
                formdata1.append('file',imageFile)
                formdata1.append('blogId',res.data.result._id)
                axios.post(`${process.env.REACT_APP_DEVELOPMENT}/upload-blog-img`,formdata1,{headers:{Accept:'application/json','Content-Type':"multipart/form-data",token:props.user.user}})
                .then(res=>{
                console.log(res)                            
                props.history.push("/blogs")
                    
                })
            })
        }
        
    }
    const imageSubmit = (image,file)=>{
        setImage(image)
        setImageFile(file)
    }

    const handleDelete = async ()=>{
        if(props.location.state.img){
            const res = await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/delete-blog-img`,{img:props.location.state.img},{headers:{token:props.user.user}})
            console.log(res)
        }
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/deleteBlog`,{id:props.location.state._id},{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            setOpen2(false)
            props.history.push("/blogs")
        })
        .catch(err=>{
            console.log(err)
        })
    }

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 p-0">
            <Dashhead id={8} display={display} />
            </div>
            <EditProfileImage
            open={open}
            setOpen={setOpen}
            handleSubmit = {imageSubmit}
            />
            <TwoBDialog 
            open={open2}
            setOpen={setOpen2}
            handleSubmit = {handleDelete}
            title="Delete Blog"
            description="Are you sure you want to delete this blog"
            leftButton="Cancel"
            rightButton="Delete"
            />
            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container p-0" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>


            <section className="home-container p-4">
                <h1 className="">{props.location.state?"Edit Blog":"Create Blog"}</h1>
                {props.location.state&&<Button variant="outlined" color="error" className="my-2" onClick={()=>{
                    setOpen2(true)
                }}>Delete Blog</Button>}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField className="my-3" id="outlined-basic" variant="outlined" fullWidth label="Title" {...register('title',{required:true})} />
                    <TextField className="my-3" id="outlined-basic" variant="outlined" fullWidth label="Description" {...register('description',{required:true})} />
                    {!image&&<Button onClick={()=>setOpen(true)}>Upload Image</Button>}
                        <div>
                        {
                            image&&<img className="my-3" style={{borderRadius:"100%"}} src={image} alt="profileimage" />
                        }
                        </div>
                        {image&&<Button onClick={()=>setImage(null)}>Delete Image</Button>}
                    <Button type="submit" variant="contained">{props.location.state?"Edit Blog":"Create Blog"}</Button>
                </form>

            </section>



             </div>
    </div>
    )
}

  const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
  }
  export default connect(mapStateToProps)(CreateBlog)
