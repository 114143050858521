import React from 'react'
import "./Jobs.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Rating } from '@mui/material';
import {useParams} from 'react-router-dom'
import moment from 'moment'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import WorkIcon from '@mui/icons-material/Work';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ArticleIcon from '@mui/icons-material/Article';
import DescriptionIcon from '@mui/icons-material/Description';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import Chip from '@mui/material/Chip';
import SearchIcon from '@mui/icons-material/Search';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditProfileImage from './EditProfileImage';
import TwoBDialog from '../Utils/TwoBDialog'
import {connect} from 'react-redux'
function JobDetail(props) {
    let params = useParams();
    console.log(props)
    console.log(params)
    const [display,setDisplay]=React.useState(false)
    const [singleJob,setSingleJob] = React.useState(null)
    const [open,setOpen] = React.useState(false)
    const [open2,setOpen2] = React.useState(false)

    const getSingleJob = ()=>{
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/job/singleJob`,{jobId:params.id})
        .then(res=>{
            console.log(res)
            if(res.data.msg==="success"){
                setSingleJob(res.data.result)
            }
        })
        .catch(err=>{
            console.log(err)
        })
    }

    React.useEffect(()=>{
        getSingleJob()
    },[])


    const renderStatus = (status)=>{
        if(status==="Rejected"){
            return "rejected"
        }else if(status==="Hired"){
            return "hired"
        }else{
            return ""
        }
    }

    const renderImageString = (createdBy)=>{
        console.log(createdBy)
        if(createdBy){
            if(Array.isArray(createdBy) && createdBy.length>0){
                if(createdBy[0].companyImg.length>0){
                    return `${process.env.REACT_APP_DEVELOPMENT}/api/image/${createdBy[0].companyImg}`
                }else{
                    return '/job-offer.png'
                }
                
            }else if(createdBy.companyImg){
                if(createdBy.companyImg.length>0){
                    return `${process.env.REACT_APP_DEVELOPMENT}/api/image/${createdBy.companyImg}`
                }else{
                    return '/job-offer.png'
                }
                
            }else{
                return '/job-offer.png'
            }
        }
    
    }

    const handleSubmit = ()=>{
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/job/deleteJobAdmin`,{jobId:singleJob._id},{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            props.history.push("/jobs")
        })
    }

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 p-0">
            <Dashhead id={2} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container p-0" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>

            
            {singleJob&&<section className="home-container p-4">

            {props.user.userInfo.type===1&&<>
            <EditProfileImage handleSubmit = {getSingleJob} jobId={singleJob._id} open={open} setOpen={setOpen} />
            <TwoBDialog 
            open={open2}
            setOpen={setOpen2}
            title="Delete Job"
            description="Are you sure you want to delete this job"
            leftButton="Cancel"
            rightButton="Delete"
            handleSubmit = {handleSubmit}
            />
            <Button onClick={()=>setOpen(true)} variant="contained" className="mb-4" >Change Job Image</Button>
            <Button onClick={()=>{
            setOpen2(true)
            }} className="ml-3 mb-4" variant="outlined" color="error">Delete Job</Button>
            <Button onClick={()=>props.history.push("/createjob",singleJob)} className="ml-3 mb-4">Edit Job</Button>
            </>}
            <div className="singlejob-first-row row m-auto">
                <div className="col-8 job-content">
                    <div className="row m-auto">
                        <div className="col-3 img-div">
                        <img src={singleJob.createdByAdmin?renderImageString(singleJob.createdByAdmin):renderImageString(singleJob.createdBy)} alt="logo1" />
                        </div>
                        <div className="col-9 content-div">
                            <h2>{singleJob.title}</h2>
                            <p className="company-name m-0">{singleJob.createdBy?singleJob.createdBy.companyName:singleJob.createdByAdmin.companyName}</p>
                        <h4 className="m-0">{singleJob.product}</h4>
                            <div className='row m-auto align-items-center'>
                                <div>
                                <Rating name="read-only" value={3} readOnly />
                                </div>
                                <div>
                                <p className="total-reviews">(47 Reviews)</p>
                                </div>
                            </div>

                            <div className="row my-2 mx-auto key-features">
                            <div className="m-1">
                                <WorkIcon />
                                <span className='key-headline m-2'>{singleJob.experience.min} - {singleJob.experience.max} Yrs</span>
                            </div>
                            <div className="m-1">
                                <CurrencyRupeeIcon />
                                <span className='key-headline m-2'>{singleJob.ctc.min} - {singleJob.ctc.max} P.A</span>
                            </div>
                            <div className="m-1">
                                <FmdGoodIcon />
                                <span className='key-headline m-2'>{singleJob.jobLocation.city} | {singleJob.jobLocation.state} | {singleJob.jobLocation.country}</span>
                            </div>
                        </div>
                        <div className="row my-2 mx-auto key-features">
                            <div className="m-1">
                                <ArticleIcon />
                                <span className='key-headline m-2'>{singleJob.qualification.ug} in CSE</span>
                            </div>
                            <div className="m-1">
                                <DescriptionIcon />
                                <span className='key-headline m-2'>{singleJob.qualification.pg} in Finance</span>
                            </div>
                            <div className="m-1">
                                <Inventory2Icon />
                                <span className='key-headline m-2'>{singleJob.industry}</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* row of first content ends of img */}


                        <div className="description">
                            <h2 className="pt-2">Job Description</h2>
                            <p>
                            {singleJob.jobDescription}
                            </p>
                        </div>

                        <div className="keys">
                        {singleJob.tags.map((tag,index)=><Chip color="primary" key={index} className="m-3" label={tag} />)}
                        </div>

                        <div className="description">
                            <h2 className="pt-2">Roles and Responsibilities</h2>
                            <ul>
                            {
                                singleJob.roleResp.map((item,index)=><li key={index}>{item}</li>)
                            }
                            </ul>
                        </div>

                        <div className="description">
                            <h2 className="pt-2">Desired Candidate Profile</h2>
                            <p>
                                {singleJob.desiredProfile}
                            </p>
                            
                        </div>
                </div>
                <div className="col-4 shadow-sm recruiter-content">
                    <div className="row m-auto">
                        <div className="img-div col-3">
                        <img src={`${process.env.REACT_APP_DEVELOPMENT}/api/image/${singleJob.createdBy?singleJob.createdBy.profilePicture:singleJob.createdByAdmin.profilePicture}`} alt="logo1" />
                        </div>
                        <div className="content-div col-9">
                            <h2>{singleJob.createdBy?singleJob.createdBy.fullName:singleJob.createdByAdmin.fullName}</h2>
                            <p>{singleJob.createdBy?singleJob.createdBy.companyName:singleJob.createdByAdmin.companyName}</p>
                            <p><LocalPhoneIcon sx={{marginRight:.4}} />{singleJob.createdBy?singleJob.createdBy.mobileNo:singleJob.createdByAdmin.mobileNo}</p>
                            <p><AlternateEmailIcon sx={{marginRight:.4}} />{singleJob.createdBy?singleJob.createdBy.email:singleJob.createdByAdmin.email}</p>
                            <p><LocationOnIcon sx={{marginRight:.4}} />{singleJob.createdBy?singleJob.createdBy.userLocation.city:singleJob.createdByAdmin.userLocation.city}</p>
                            <p>{singleJob.createdBy?singleJob.createdBy.companyDescription:singleJob.createdByAdmin.companyDescription}</p>
                            <Button variant="contained" fullWidth onClick={()=>props.history.push(`/recruiterdetail/${singleJob.createdBy?singleJob.createdBy._id:singleJob.createdByAdmin._id}`)}>View Profile</Button>
                        </div>
                    </div>
                </div>
            </div>

            <h2>Candidate Applications ({singleJob.jobCandidates.length})</h2>

            <div className="row m-auto can-parent justify-content-around">
                {
                    singleJob.jobCandidates.map((item,index)=><div className={`col-5 can-child row m-auto ${renderStatus(item.status)}`} key={index}>
                    <div className='col-2 img-div'>
                    <img src={`${process.env.REACT_APP_DEVELOPMENT}/api/image/${item.user.profilePicture}`} alt="logo1" />
                    </div>
                    <div className="col-10 con-div">
                        <h4>Sharjeel sk</h4>
                        <p>B.Tech, CSE IIT</p>
                        <div className="row m-auto sub-info">
                        <p className="mx-2"><LocalPhoneIcon sx={{marginRight:.1}} /> <b>{item.user.mobileNo}</b></p>
                        <p className="mx-2"><AlternateEmailIcon sx={{marginRight:.1}} /> <b>{item.user.email}</b></p>
                        <p className="mx-2"><LocationOnIcon sx={{marginRight:.1}} /> <b>{item.user.userLocation.city}, {item.user.userLocation.state}</b></p>
                        </div>
                        <p className="my-3">{item.user.resumeTagline}</p>
                    </div>
                </div>)
                }
                

            </div>

            </section>}


             </div>
    </div>
    )
}
const columns2 = [
    { field: 'id', headerName: 'ID',width:20},
    //{ field: 'brand', headerName: 'Brand Name',valueGetter:(param)=>param.value.name,width:150},
    { field: 'title', headerName: 'Job Title',valueGetter:(param)=>param.row.title,width:250},
    { field: 'companyName', headerName: 'Company Name',valueGetter:(param)=>param.row.companyName,width:200},
    { field: 'jobLocation', headerName: 'Location',valueGetter:(param)=>param.row.jobLocation.city,width:150},
    { field: 'product', headerName: 'Product',valueGetter:(param)=>param.row.product,width:150},
    { field: 'industry', headerName: 'Industry',valueGetter:(param)=>param.row.industry,width:150},
    { field: 'functionalArea', headerName: 'Functional Area',valueGetter:(param)=>param.row.functionalArea,width:150},
    {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}


  ];
  const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
  }
  export default connect(mapStateToProps)(JobDetail)
