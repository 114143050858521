import React from 'react'
import "./Home.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from './Dashhead';
import axios from 'axios';
import { CChart } from '@coreui/react-chartjs';
import _ from 'lodash'
import { Button } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import Rating from '@mui/material/Rating';
import WorkIcon from '@mui/icons-material/Work';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ArticleIcon from '@mui/icons-material/Article';
import DescriptionIcon from '@mui/icons-material/Description';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import Chip from '@mui/material/Chip';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom'
import BookmarkIcon from '@mui/icons-material/Bookmark';
import {connect} from 'react-redux'
function Home(props) {
    const [display,setDisplay]=React.useState(false)
    const [dashboardData,setDashboardData] = React.useState(null)
    React.useEffect(()=>{
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getJobData`,{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            setDashboardData(res.data.result)
        })
        .catch(err=>{
            console.log(err)
        })
    },[])
    console.log(dashboardData)

    const getLastMonths = ()=>{
        var monthNames = ["1","2","3","4","5","6","7","8","9","10","11","12"];

        var today = new Date();
        var d;
        var month;

        let finalMonths = []
        
        for(var i = 5; i >= 0; i -= 1) {
          d = new Date(today.getFullYear(), today.getMonth() - i, 1);
          month = monthNames[d.getMonth()];
          finalMonths.push(month)
        }
        return finalMonths.reverse()
    }
    const renderImageString = (createdBy)=>{
        if(Array.isArray(createdBy)){
            if(createdBy.length>0){
                if(createdBy[0].companyImg.length>0){
                    return `${process.env.REACT_APP_DEVELOPMENT}/api/image/${createdBy[0].companyImg}`
                }else{
                    return '/job-offer.png'
                }
            }
            
            
        }else if(createdBy.companyImg){
            if(createdBy.companyImg.length>0){
                return `${process.env.REACT_APP_DEVELOPMENT}/api/image/${createdBy.companyImg}`
            }else{
                return '/job-offer.png'
            }
            
        }else{
            return '/job-offer.png'
        }
    }
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 p-0">
            <Dashhead id={1} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container p-0" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>

            {dashboardData&&<section className="home-container">
                <div className="row m-auto justify-content-around box-parent">
                    <div className="col-3 box-info shadow-sm">
                        <h3>Total Jobs Created</h3>
                        <h1>{dashboardData.jobCount}</h1>
                    </div>
                    <div className="col-3 box-info shadow-sm">
                        <h3>Total Candidates / Premium</h3>
                        <h1>{dashboardData.candidateCount} / {dashboardData.candidatePremiumCount}</h1>
                    </div>
                    <div className="col-3 box-info shadow-sm">
                        <h3>Total Recruiters / Premium</h3>
                        <h1>{dashboardData.recruiterCount} / {dashboardData.recruiterPremiumCount}</h1>
                    </div>
                </div>


                <div className="row m-auto align-items-center chart-row">

                <div className="chart-col col-4">
                <h5>Number of Job Creations (Last 6 Month)</h5>
                {dashboardData&&<CChart
                type="bar"
                data={{
                    labels: dashboardData.job[0].sixMonthJobs.map(item=>`${item._id.month}/${item._id.year}`),
                    datasets: [
                    {
                        label: 'Number of jobs',
                        backgroundColor: '#ff3d8b',
                        data: dashboardData.job[0].sixMonthJobs.map(item=>item.count),
                    },
                    ],
                }}
                labels="months"
                />}
                </div>

                <div className="chart-col col-4">
                <h5>Candidates on Free Plan</h5>
                <CChart
                type="line" 
                data={{
                    labels: dashboardData.candidate[0].normalCandidates.map(item=>`${item._id.month}/${item._id.year}`),
                    datasets: [
                    {
                        label: "Free Candidates",
                        backgroundColor: "#FFE7F0",
                        borderColor: "#E77AA5",
                        pointBackgroundColor: "#ff3d8b",
                        pointBorderColor: "#fff",
                        data: dashboardData.candidate[0].normalCandidates.map(item=>item.count)
                    }
                    ],
                }}
                />
                </div>

                <div className="chart-col col-4">
                <h5>Candidates on Premium Plan</h5>
                <CChart
                type="line" 
                data={{
                    labels: dashboardData.candidate[0].premiumCandidates.map(item=>`${item._id.month}/${item._id.year}`),
                    datasets: [
                    {
                        label: "Premium Candidates",
                        backgroundColor: "#FFE7F0",
                        borderColor: "#E77AA5",
                        pointBackgroundColor: "#ff3d8b",
                        pointBorderColor: "#fff",
                        data: dashboardData.candidate[0].premiumCandidates.map(item=>item.count)
                    }
                    ],
                }}
                />
                </div>

                <div className="chart-col col-4">
                <h5>Recruiters on Free Plan</h5>
                <CChart
                type="line" 
                data={{
                    labels: dashboardData.recruiter[0].normalRecruiters.map(item=>`${item._id.month}/${item._id.year}`),
                    datasets: [
                    {
                        label: "Free Recruiters",
                        backgroundColor: "#FFE7F0",
                        borderColor: "#E77AA5",
                        pointBackgroundColor: "#ff3d8b",
                        pointBorderColor: "#fff",
                        data: dashboardData.recruiter[0].normalRecruiters.map(item=>item.count)
                    }
                    ],
                }}
                />
                </div>

                <div className="chart-col col-4">
                <h5>Recruiters on Premium Plan</h5>
                <CChart
                type="line" 
                data={{
                    labels: dashboardData.recruiter[0].premiumRecruiters.map(item=>`${item._id.month}/${item._id.year}`),
                    datasets: [
                    {
                        label: "Premium Recruiters",
                        backgroundColor: "#FFE7F0",
                        borderColor: "#E77AA5",
                        pointBackgroundColor: "#ff3d8b",
                        pointBorderColor: "#fff",
                        data: dashboardData.recruiter[0].premiumRecruiters.map(item=>item.count)
                    }
                    ],
                }}
                />
                </div>

                </div>


                {/*========================= chart section ends=============================== */}

                <div className="row m-auto users-row justify-content-around">
                    <div className="col-3 shadow-sm users-child">
                        <h3>Latest Premium Candidates</h3>
                        {
                            dashboardData.candidate[0].latestPremiumCandidates.map((item,index)=><div className="single-user-prem row m-auto align-items-center" key={index}>
                                <div className="col-4 img-div">
                                    <img src={!item.profilePicture?"/profile.png":`${process.env.REACT_APP_DEVELOPMENT}/api/image/${item.profilePicture}`} alt="profile" />
                                </div>
                                <div className="col-8 text-div">
                                    <p className="heading">{item.fullName}</p>
                                    <p>{item.mobileNo}</p>
                                    <p className="email">{item.email}</p>
                                    <p>{item.userLocation.city} | {item.userLocation.state} | {item.userLocation.country}</p>
                                </div>
                            </div>)
                        }
                        <div style={{textAlign:"right"}}>
                        <Button onClick={()=>props.history.push("/candidates")}>View All</Button>
                        </div>
                    </div>
                    <div className="col-3 shadow-sm users-child">
                        <h3>Latest Premium Recruiters</h3>
                        {
                            dashboardData.recruiter[0].latestPremiumRecruiters.map((item,index)=><div className="single-user-prem row m-auto align-items-center" key={index}>
                                <div className="col-4 img-div">
                                    <img src={!item.profilePicture?"/profile.png":`${process.env.REACT_APP_DEVELOPMENT}/api/image/${item.profilePicture}`} alt="profile" />
                                </div>
                                <div className="col-8 text-div">
                                    <p className="heading">{item.fullName}</p>
                                    <p>{item.mobileNo}</p>
                                    <p className="email">{item.email}</p>
                                    <p>{item.userLocation.city} | {item.userLocation.state} | {item.userLocation.country}</p>
                                </div>
                            </div>)
                        }
                        <div style={{textAlign:"right"}}>
                        <Button onClick={()=>props.history.push("/recruiters")}>View All</Button>
                        </div>
                    </div>
                    <div className="col-3 shadow-sm users-child">
                        <h3>Latest Candidates</h3>
                        {
                            dashboardData.candidate[0].latestCandidates.map((item,index)=><div className="single-user row m-auto align-items-center" key={index}>
                                <div className="col-4 img-div">
                                    <img src={!item.profilePicture?"/profile.png":`${process.env.REACT_APP_DEVELOPMENT}/api/image/${item.profilePicture}`} alt="profile" />
                                </div>
                                <div className="col-8 text-div">
                                    <p className="heading">{item.fullName}</p>
                                    <p>{item.mobileNo}</p>
                                    <p className="email">{item.email}</p>
                                    <p>{item.userLocation.city} | {item.userLocation.state} | {item.userLocation.country}</p>
                                </div>
                            </div>)
                        }
                        <div style={{textAlign:"right"}}>
                        <Button onClick={()=>props.history.push("/candidates")}>View All</Button>
                        </div>
                    </div>
                    <div className="col-3 shadow-sm users-child">
                        <h3>Latest Recruiters</h3>
                        {
                            dashboardData.recruiter[0].latestRecruiters.map((item,index)=><div className="single-user row m-auto align-items-center" key={index}>
                                <div className="col-4 img-div">
                                    <img src={!item.profilePicture?"/profile.png":`${process.env.REACT_APP_DEVELOPMENT}/api/image/${item.profilePicture}`} alt="profile" />
                                </div>
                                <div className="col-8 text-div">
                                    <p className="heading">{item.fullName}</p>
                                    <p>{item.mobileNo}</p>
                                    <p className="email">{item.email}</p>
                                    <p>{item.userLocation.city} | {item.userLocation.state} | {item.userLocation.country}</p>
                                </div>
                            </div>)
                        }
                        <div style={{textAlign:"right"}}>
                        <Button onClick={()=>props.history.push("/recruiters")}>View All</Button>
                        </div>
                    </div>
                    <div className="col-3 shadow-sm users-child">
                        <h3>Latest Jobs</h3>
                        {
                            dashboardData.job[0].latestJobs.map((item,index)=><div className="single-user row m-auto align-items-center" key={index}>
                                <div className="col-4 img-div">
                                <img src={item.createdByAdmin?renderImageString(item.createdByAdmin):renderImageString(item.createdBy)} alt="logo1" />
                                </div>
                                <div className="col-8 text-div">
                                    <p className="heading">{item.title}</p>
                                    <p>{item.product}</p>
                                    <p className="email">{item.industry}</p>
                                    <p>{item.jobLocation.city} | {item.jobLocation.state} | {item.jobLocation.country}</p>
                                </div>
                            </div>)
                        }
                        <div style={{textAlign:"right"}}>
                        <Button onClick={()=>props.history.push("/recruiters")}>View All</Button>
                        </div>
                    </div>
                </div>

                {/*========================= chart section ends=============================== */}






            </section>
}



             </div>
    </div>
    )
}
const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
}
export default connect(mapStateToProps)(Home)
// dashboardData.candidate[0].normalCandidates.map(item=>item.count)